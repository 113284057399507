import { Check, Close, Edit, Undo } from '@mui/icons-material';
import { Stack, Button, Switch, Input } from '@mui/material';
import { useEffect, useState } from 'react';
import {
  BooleanField,
  Datagrid,
  FunctionField,
  List,
  TextField,
  useListContext,
  useNotify,
  usePrevious,
  useRefresh,
  useUnselectAll,
  useUpdate,
} from 'react-admin';
import SettlementTypeAside from './SettlementTypeAside';
import { settlementTypeFilters, useGetFilters } from './SettlementTypeFilters';

const UpdateFee = ({ record }: { record: any }) => {
  const notify = useNotify();
  const [editMode, setEditMode] = useState(false);
  const [initialValue, setInitialValue] = useState(record.serviceFee);
  const [fee, setFee] = useState(record.serviceFee);
  const { selectedIds } = useListContext();

  const [update, { isLoading, isSuccess }] = useUpdate();
  const prevIsLoading = usePrevious(isLoading);

  useEffect(() => {
    if (!isLoading && prevIsLoading) {
      setEditMode(false);
      setInitialValue(fee);
      notify('Settlement type updated', { type: 'success' });
    }
  }, [isSuccess, isLoading, prevIsLoading]);

  if (!editMode) {
    return (
      <Stack direction="row" alignItems="center" spacing={1} flex={1}>
        <TextField label="Fee" source="serviceFee" />
        <Button
          className="edit-button"
          variant="outlined"
          color="inherit"
          startIcon={<Edit fontSize="small" />}
          size="small"
          disabled={selectedIds.includes(record.id)}
          onClick={() => {
            setEditMode(true);
          }}
        >
          Edit
        </Button>
      </Stack>
    );
  }

  return (
    <Stack direction="row" alignItems="center" spacing={1} flex={1}>
      <Input value={fee} onChange={(e) => setFee(parseFloat(e.target.value))} />
      <Stack direction="row" alignItems="center" spacing={1}>
        <Button
          variant="outlined"
          color="inherit"
          startIcon={<Check />}
          size="small"
          disabled={fee === initialValue || isNaN(fee) || parseFloat(fee) < 0}
          onClick={async () => {
            try {
              await update(
                'settlementTypes',
                {
                  id: record.id,
                  data: { serviceFee: parseFloat(fee) },
                },
                {
                  onSuccess: () => {
                    console.log(`success: ${record.id}`);
                  },
                  onError: (err) => {
                    console.log(`error: ${record.id}`, err);
                  },
                  mutationMode: 'pessimistic',
                }
              );
            } catch (error) {
              console.error(error);
            }
          }}
        >
          Update
        </Button>
        <Button
          startIcon={<Close />}
          size="small"
          color="inherit"
          onClick={() => {
            setEditMode(false);
          }}
        >
          Cancel
        </Button>
      </Stack>
    </Stack>
  );
};

const UpdateStatus = ({
  record,
  selected,
  setSelected,
}: {
  record: any;
  selected: boolean | undefined;
  setSelected: any;
}) => {
  const { selectedIds } = useListContext();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelected(event.target.checked);
  };

  if (selectedIds.includes(record.id)) {
    return (
      <Switch
        sx={{ my: -0.25 }}
        size="small"
        checked={selected == undefined ? record.enabled : selected}
        onChange={handleChange}
        inputProps={{ 'aria-label': 'controlled' }}
      />
    );
  }

  return <BooleanField label="Enabled" source="enabled" />;
};

const BulkActions = ({
  selected,
  setSelected,
}: {
  selected: boolean | undefined;
  setSelected: any;
}) => {
  const refresh = useRefresh();
  const { selectedIds } = useListContext();
  const unselectAll = useUnselectAll('settlementTypes');

  // const [updateMany, { isLoading, error }] = useUpdateMany();
  const [update, { isLoading, isSuccess, isError }] = useUpdate();
  const prevIsLoading = usePrevious(isLoading);

  useEffect(() => {
    if (!isLoading && prevIsLoading) {
      // if (isError) {
      //   notify('Error: Settlement type not updated', { type: 'error' });
      // }
      setSelected(false);
      unselectAll();
      notify('Settlement type updated', { type: 'success' });
    }
  }, [isSuccess, isLoading, prevIsLoading, setSelected, unselectAll]);

  const notify = useNotify();

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Button
        variant="outlined"
        color="inherit"
        startIcon={<Check />}
        size="small"
        disabled={!selectedIds.length || selected == undefined}
        onClick={async () => {
          try {
            await Promise.all(
              selectedIds.map((id) => {
                return update(
                  'settlementTypes',
                  {
                    id,
                    data: { isActive: selected },
                  },
                  {
                    onSuccess: () => {
                      console.log(`success: ${id}`);
                    },
                    onError: (err) => {
                      console.log(`error: ${id}`, err);
                    },
                    mutationMode: 'pessimistic',
                  }
                );
              })
            ).then(() => {
              refresh();
            });
          } catch (error) {
            console.error(error);
          }
        }}
      >
        Update
      </Button>
      <Button
        startIcon={<Undo />}
        size="small"
        color="inherit"
        onClick={() => {
          setSelected(false);
          unselectAll();
        }}
      >
        Undo
      </Button>
    </Stack>
  );
};

const SettlementTypesList = () => {
  const { settlementTypes, paymentTypes, statusTypeChoices } = useGetFilters();
  const [status, setStatus] = useState(undefined);

  return (
    <List
      exporter={false}
      aside={<SettlementTypeAside />}
      resource="settlementTypes"
      filters={settlementTypeFilters({
        settlementTypes,
        paymentTypes,
        statusTypeChoices,
      })}
    >
      <Datagrid
        bulkActionButtons={
          <BulkActions selected={status} setSelected={setStatus} />
        }
        rowSx={(record) => ({
          '& .edit-button': {
            visibility: 'hidden',
          },
          '&:hover': {
            '& .edit-button': {
              visibility: 'visible',
            },
          },
        })}
      >
        <TextField label="ID" source="name" />
        <TextField label="Payment Type" source="paymentTypeDesc" />
        <FunctionField
          label="Fee"
          render={(record: any) => <UpdateFee record={record} />}
        />
        <FunctionField
          label="Enabled"
          render={(record: any) => (
            <UpdateStatus
              record={record}
              selected={status}
              setSelected={setStatus}
            />
          )}
        />
      </Datagrid>
    </List>
  );
};

export default SettlementTypesList;
