import CardProviderCreate from './CardProviderCreate';
import CardProviderEdit from './CardProviderEdit';
import CardProviderList from './CardProviderList';

const resource = {
  list: CardProviderList,
  edit: CardProviderEdit,
  create: CardProviderCreate,
};

export default resource;
