import { NoteAlt } from '@mui/icons-material';
import NoteShow from './NoteShow';
import NoteList from './NoteList';

const resource = {
  list: NoteList,
  show: NoteShow,
  icon: NoteAlt,
  // recordRepresentation: (record: any) => record.accountHolderName,
  // create: PayeeCreate,
};

export default resource;
