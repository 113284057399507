import {
  Show,
  SimpleShowLayout,
  TextField,
  DateField,
  useRecordContext,
  useNotify,
  useRefresh,
  Labeled,
} from 'react-admin';
import {
  Grid,
  Typography,
  Stack,
  Button,
  TextField as MuiTextField,
} from '@mui/material';
import { useEffect, useState } from 'react';

const ApiRateLimitsTitle = () => {
  const record = useRecordContext();
  return <span>API Rate Limit {record ? `"${record.apiPath}"` : ''}</span>;
};

const RateLimitField = () => {
  const record = useRecordContext();
  const notify = useNotify();
  const refresh = useRefresh();
  const [rateLimit, setRateLimit] = useState('');
  const [updating, setUpdating] = useState(false);

  useEffect(() => {
    if (record?.rateLimit) {
      setRateLimit(record.rateLimit.toString());
    }
  }, [record]);

  const handleUpdate = async () => {
    try {
      setUpdating(true);
      const response = await fetch(
        `${import.meta.env.VITE_API_URL}/apis/rateLimits/${record.id}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('auth')}`,
          },
          body: JSON.stringify({ rateLimit: parseInt(rateLimit) }),
        }
      );

      if (response.ok) {
        notify('Rate limit updated successfully', { type: 'success' });
        refresh();
      } else {
        notify('Error updating rate limit', { type: 'error' });
      }
    } catch (error) {
      notify('Error updating rate limit', { type: 'error' });
    } finally {
      setUpdating(false);
    }
  };

  return (
    <Stack direction="row" spacing={2} alignItems="flex-end">
      <Labeled source="rateLimit">
        <MuiTextField
          type="number"
          value={rateLimit}
          onChange={(e) => setRateLimit(e.target.value)}
          inputProps={{ min: 1 }}
          size="small"
        />
      </Labeled>
      <Button
        variant="contained"
        onClick={handleUpdate}
        disabled={updating || parseInt(rateLimit) === record?.rateLimit}
        size="small"
      >
        Update
      </Button>
    </Stack>
  );
};

const ApiRateLimitsShow = () => {
  return (
    <Show title={<ApiRateLimitsTitle />}>
      <SimpleShowLayout>
        <Grid container width={{ xs: '100%', xl: 800 }} spacing={2}>
          <Grid item md={12} minWidth="100%">
            <Typography variant="h6" gutterBottom>
              Rate Limit Details
            </Typography>
            <Grid container>
              <Grid item xs={12} sm={12} md={6}>
                <Labeled source="apiPath">
                  <TextField source="apiPath" />
                </Labeled>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <RateLimitField />
              </Grid>
              <Grid item xs={12} sm={12} md={6} mt={1}>
                <Labeled source="createdAt">
                  <DateField
                    source="createdAt"
                    showTime
                    options={{
                      timeZone: 'Asia/Kolkata',
                      hour12: true,
                      hour: 'numeric',
                      minute: 'numeric',
                    }}
                  />
                </Labeled>
              </Grid>
              <Grid item xs={12} sm={12} md={6} mt={1}>
                <Labeled source="updatedAt">
                  <DateField
                    source="updatedAt"
                    showTime
                    options={{
                      timeZone: 'Asia/Kolkata',
                      hour12: true,
                      hour: 'numeric',
                      minute: 'numeric',
                    }}
                  />
                </Labeled>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </SimpleShowLayout>
    </Show>
  );
};

export default ApiRateLimitsShow;
