import {
  BooleanField,
  Datagrid,
  DateField,
  List,
  SearchInput,
  TextField,
  usePermissions,
} from 'react-admin';

import CustomerReferenceField from '../customers/CustomerReferenceField';
import { KYCShowContent } from './KYCShow';

const kycFilters = [<SearchInput source="q" />];

const KYCListContent = () => {
  const { permissions } = usePermissions();
  return (
    <Datagrid
      rowClick={permissions?.includes('kyc_view') ? 'expand' : false}
      bulkActionButtons={false}
      expand={permissions?.includes('kyc_view') ? KYCShowContent : undefined}
    >
      <CustomerReferenceField link={false} />
      <TextField source="kycModuleTypeId" label="KYC Module" />
      <BooleanField source="validated" label="Validated" />
      <DateField
        source="createdAt"
        label="Created At"
        showTime
        options={{
          month: 'short',
          day: 'numeric',
          hour12: true,
          hour: 'numeric',
          minute: 'numeric',
        }}
      />
    </Datagrid>
  );
};

const KYCList = () => {
  return (
    <List
      // exporter={false}
      sort={{ field: 'createdAt', order: 'DESC' }}
      perPage={25}
      filters={kycFilters}
    >
      <KYCListContent />
    </List>
  );
};

export default KYCList;
