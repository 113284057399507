import {
  AutocompleteInput,
  DateField,
  Datagrid,
  ExportButton,
  FilterButton,
  List,
  NumberField,
  ReferenceInput,
  TextField,
  NumberInput,
  TopToolbar,
  useListContext,
  downloadCSV,
  SelectInput,
  DateTimeInput,
  FunctionField,
  SearchInput,
  useGetList,
  useUnselectAll,
  useUpdate,
  useNotify,
  useCreate,
  Link,
  useUpdateMany,
  useRefresh,
} from 'react-admin';
import {
  Typography,
  Stack,
  MenuItem,
  Select,
  Button,
  Switch,
} from '@mui/material';
// import CustomerReferenceField from '../customers/CustomerReferenceField';
// import { cardStatusTitles, Customer, Order } from '../types';
import jsonExport from 'jsonexport/dist';
import CheckCircleOutline from '@mui/icons-material/CheckCircleOutline';
import {
  WarningAmberOutlined,
  PendingOutlined,
  Check,
  Undo,
} from '@mui/icons-material';
import { useMemo, useState } from 'react';
import MappingsListAside from './MappingsListAside';
import { orderFilters, useGetFilters } from './MappingsFilters';
// import CardListAside from './CardListAside';

export const PaymentTypes: Record<string, string> = {
  VENDOR_PAYMENT: 'Vendor',
  RENTAL_PAYMENT: 'Rental',
  EDUCATIONAL_PAYMENT: 'Education',
};

export const SettlementTypes: Record<string, string> = {
  INSTANT_PAY: 'Instant Pay',
  FAST_PAY: 'Fast Pay',
  REGULAR_PAY: 'Regular Pay',
};

const UpdateMerchantCode = ({
  data,
  record,
  selected,
  setSelected,
}: {
  data: any;
  record: any;
  selected: string;
  setSelected: any;
}) => {
  const { selectedIds } = useListContext();

  const value =
    selected && selectedIds.includes(record.id)
      ? selected
      : record.merchantCode;

  // const merchantCodes = data?.reduce((acc: any, item: any) => {
  //   if (!acc[item.merchantId]) {
  //     acc[item.merchantId] = item;
  //   }
  //   return acc;
  // });

  return (
    <Select
      size="small"
      sx={{
        minWidth: 200,
        p: 0,
        m: 0,
        fontSize: 14,
        '& .MuiSelect-select': {
          py: 0.15,
          backgroundColor:
            selected && selectedIds.includes(record.id)
              ? 'rgb(33 150 243 / 40%)'
              : 'transparent',
        },
      }}
      value={value}
      onChange={(d) => {
        setSelected(d.target.value);
      }}
      disabled={!selectedIds.includes(record.id)}
    >
      {data?.map((item: any) => (
        <MenuItem value={item.id}>{item.details || item.merchantId}</MenuItem>
      ))}
    </Select>
  );
};

const ListActions = ({
  handleFilterWithUnmapped,
  filterWithUnmapped,
}: {
  handleFilterWithUnmapped: () => void;
  filterWithUnmapped: boolean;
}) => (
  <TopToolbar sx={{ alignItems: 'center' }}>
    {/* <SelectColumnsButton /> */}

    <Button
      size="small"
      id="basic-button"
      // aria-controls={open ? 'basic-menu' : undefined}
      // aria-haspopup="true"
      // aria-expanded={open ? 'true' : undefined}
      onClick={handleFilterWithUnmapped}
      // startIcon={<VisibilityIcon />}
    >
      {'Filter with unmapped'}
      <Switch
        sx={{ ml: 1 }}
        size="small"
        checked={filterWithUnmapped}
        onChange={handleFilterWithUnmapped}
      />
    </Button>

    <FilterButton />
    <ExportButton />
  </TopToolbar>
);

const MappingsListContent = () => {
  const [merchantCode, setMerchantCode] = useState('');

  const { data: merchantCodes = [] } = useGetList('merchantCodes', {
    pagination: { page: 1, perPage: 100 },
  });

  console.log(merchantCodes, 'pp2');
  const listContext = useListContext();
  const { filterValues, setFilters, displayedFilters, ...rest } = listContext;
  return (
    <Datagrid
      bulkActionButtons={
        <BulkActions selected={merchantCode} setSelected={setMerchantCode} />
      }
      rowSx={(record, index) => ({
        opacity:
          record.billPayStatus === 'FAILED' ||
          record.billPayStatus === 'CANCELLED' ||
          record.billPayStatus === 'INITIATED'
            ? 0.5
            : 1,
        '& .MuiTableCell-root': {
          whiteSpace: 'nowrap',
        },
      })}
      rowClick="show"
      sx={{ opacity: 1 }}
    >
      <TextField label="Payment" source="paymentType" />
      <TextField label="Settlement" source="settlementType" />

      <TextField label="Card" source="cardType" />
      <TextField label="Bank" source="cardBankType" />
      <TextField label="Range Type" source="rangeType" />
      <FunctionField
        label="Merchant Code"
        source="merchantCode"
        render={(record: any) => {
          return (
            <UpdateMerchantCode
              record={record}
              data={merchantCodes}
              selected={merchantCode}
              setSelected={setMerchantCode}
            />
          );
        }}
      />
    </Datagrid>
  );
};

const BulkActions = ({
  selected,
  setSelected,
}: {
  selected: string;
  setSelected: any;
}) => {
  const { selectedIds } = useListContext();
  const unselectAll = useUnselectAll('merchantCodeMapping');
  const refresh = useRefresh();

  const [updateMany, { isLoading, error }] = useUpdateMany();

  const notify = useNotify();

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Button
        variant="outlined"
        color="inherit"
        startIcon={<Check />}
        size="small"
        disabled={!selected || !selectedIds.length}
        onClick={async () => {
          try {
            await updateMany(
              'merchantCodeMapping',
              {
                data: { merchantCode: selected, ids: selectedIds },
                ids: selectedIds,
              },
              {
                onSuccess: () => {
                  console.log(`success`);
                  setSelected('');
                  unselectAll();
                  refresh();
                  notify('Merchant code updated', { type: 'success' });
                },
                onError: (err) => {
                  console.log(`error`, err);
                },
                mutationMode: 'pessimistic',
              }
            );
          } catch (error) {
            notify('Error: Merchant code not updated', { type: 'error' });
          }
        }}
      >
        Update
      </Button>
      <Button
        startIcon={<Undo />}
        size="small"
        color="inherit"
        onClick={() => {
          setSelected('');
          unselectAll();
        }}
      >
        Undo
      </Button>
    </Stack>
  );
};

const MappingsList = () => {
  const [filterWithUnmapped, setFilterWithUnmapped] = useState(false);
  const handleFilterWithUnmapped = () => {
    setFilterWithUnmapped(!filterWithUnmapped);
  };

  const filters = useGetFilters();

  return (
    <>
      <List
        sort={{ field: 'createdAt', order: 'DESC' }}
        perPage={25}
        filter={filterWithUnmapped ? { merchantCode: '' } : {}}
        filters={orderFilters(filters)}
        actions={
          <ListActions
            handleFilterWithUnmapped={handleFilterWithUnmapped}
            filterWithUnmapped={filterWithUnmapped}
          />
        }
        aside={<MappingsListAside />}
        resource="merchantCodeMapping"
      >
        <MappingsListContent />
      </List>
    </>
  );
};

export default MappingsList;
