import { numberToPriceFormat } from '../helpers';
import CardWithIcon from './CardWithIcon';
import { Typography } from '@mui/material';

const PriceWidget = ({
  value,
  title,
  subtitle,
}: {
  value?: string;
  title: string;
  subtitle?: string;
}) => {
  return (
    <CardWithIcon
      to="/orders"
      icon={() => <Typography variant="h4">₹</Typography>}
      title={title}
      subtitle={value ? `₹${numberToPriceFormat(value)}` : '--'}
    />
  );
};

export default PriceWidget;
