import { Box, Typography } from '@mui/material';
import { formatDistanceStrict } from 'date-fns';
import LetterAvatar from './avatar';

export const NoteCreatedBy = ({ data }: any) => {
  const { firstName, lastName, createdAt } = data;
  const fullName = firstName + ' ' + lastName;
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      <LetterAvatar name={fullName} scale={0.7} />
      <Box sx={{ flex: 1 }}>
        <Typography
          noWrap
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            width: '150px',
            fontSize: '14px',
          }}
        >
          {fullName}
        </Typography>
        {createdAt && (
          <Box
            sx={{
              //   position: 'absolute',
              bottom: 10,
              left: 10,
              color: 'text.secondary',
              fontSize: '.75em',
              fontWeight: 300,
            }}
          >
            {formatDistanceStrict(new Date(), new Date(createdAt))} ago
          </Box>
        )}
      </Box>
    </Box>
  );
};
