import * as React from 'react';
import { useCallback } from 'react';
import {
  AutocompleteInput,
  DatagridConfigurable,
  DateField,
  DateInput,
  ExportButton,
  FilterButton,
  List,
  NumberField,
  ReferenceInput,
  SelectColumnsButton,
  TextField,
  TextInput,
  TopToolbar,
  useListContext,
  downloadCSV,
  SelectInput,
  FunctionField,
  usePaginationState,
} from 'react-admin';
import { useMediaQuery, Divider, Theme } from '@mui/material';

import CustomerReferenceField from '../customers/CustomerReferenceField';
import MobileGrid from './MobileGrid';
import {
  Customer,
  Order,
  PaymentTypeMap,
  SettlementTypeMap,
  UserConfig,
} from '../types';
import UserConfigListAside from './UserConfigListAside';
import jsonExport from 'jsonexport/dist';
import { useGetFilters, userConfigFilters } from './UserConfigFilters';

const paymentStatusChoices = [
  { id: 'RECEIVED', name: 'Received' },
  { id: 'SETTLEMENT_INITIATED', name: 'Settlement Initiated' },
  { id: 'SETTLEMENT_COMPLETED', name: 'Settled' },
  { id: 'FAILED', name: 'Failed' },
  { id: 'CANCELLED', name: 'Cancelled' },
];

const exporter = (orders: Order[]) => {
  const ordersForExport = orders.map((order) => {
    const {
      id,
      priceBreakUp,
      payeeInfo: { payeeNickName, payerName, ...payee },
      clientPaymentId,
      clientReferenceId,
      currency,
      guestDetail,
      updatedAt,
      ...rest
    } = order;
    return {
      ...priceBreakUp,
      ...payee,
      ...rest,
      id,
    };
  });
  jsonExport(
    ordersForExport,
    {
      headers: ['id'], // order fields in the export
    },
    (_err: any, csv: any) => {
      downloadCSV(csv, 'orders'); // download as 'orders.csv` file
    }
  );
};

const UserConfigList = () => {
  const listContext = useListContext();
  const { filterValues, setFilters, displayedFilters } = listContext;
  const { settlementTypes, paymentTypes, statusTypes } = useGetFilters();

  const handleChange = useCallback(
    (event: React.ChangeEvent<{}>, value: any) => {
      setFilters &&
        setFilters(
          { ...filterValues, paymentStatus: value },
          displayedFilters,
          false // no debounce, we want the filter to fire immediately
        );
    },
    [displayedFilters, filterValues, setFilters]
  );
  const pagination = usePaginationState();
  console.log('pagination', pagination);

  return (
    <>
      <List
        filterDefaultValues={{ paymentStatus: 'RECEIVED' }}
        sort={{ field: 'createdAt', order: 'DESC' }}
        perPage={25}
        filters={userConfigFilters({
          settlementTypes,
          paymentTypes,
          statusTypes,
        })}
        // actions={<ListActions />}
        aside={<UserConfigListAside />}
        exporter={exporter}
        hasCreate
      >
        <DatagridConfigurable bulkActionButtons={false} rowClick="edit">
          <DateField
            source="createdAt"
            showTime
            options={{
              month: 'short',
              day: 'numeric',
              hour12: true,
              hour: 'numeric',
              minute: 'numeric',
            }}
          />
          <CustomerReferenceField link="show" pagination={pagination} />
          <FunctionField
            label="Configs"
            render={(record: UserConfig) => {
              const list = record.settlementTypeList.map(
                (settlementType) =>
                  `${PaymentTypeMap[settlementType.paymentTypeId]}, ${
                    SettlementTypeMap[settlementType.settlementTypeId]
                  } - ${settlementType.serviceFee}% (${
                    settlementType.active ? 'Active' : 'Inactive'
                  })`
              );
              return list.join(', ');
            }}
          />
        </DatagridConfigurable>
      </List>
    </>
  );
};

export default UserConfigList;
