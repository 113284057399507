import { Switch } from '@mui/material';

import { Grid } from '@mui/material';
import { useState } from 'react';
import { Labeled, useNotify, useRefresh } from 'react-admin';
import { apiUrl } from '../constants';

const updateCardSelectionRequired = async ({
  customerId,
  cardSelectionRequired,
}: {
  customerId: string;
  cardSelectionRequired: boolean;
}) => {
  const formdata = new FormData();
  formdata.append('cardSelectionRequired', cardSelectionRequired.toString());

  const response = await fetch(`${apiUrl}/customers/${customerId}`, {
    method: 'PUT',
    body: formdata,
    headers: {
      Authorization: localStorage.getItem('auth') || '',
    },
  }).then((res) => res.json());

  return response;
};

export const CardSelectionRequired = ({ record }: any) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const [cardSelectionRequired, setCardSelectionRequired] = useState(
    record?.cardSelectionRequired
  );
  const [loading, setLoading] = useState(false);

  const handleCardSelectionRequired = async (event: any) => {
    setLoading(true);
    const response = await updateCardSelectionRequired({
      customerId: record?.userId,
      cardSelectionRequired: event.target.checked,
    });
    if (response.code === 'MSG_0067') {
      setCardSelectionRequired((prev: any) => !prev);
      notify('Card selection required updated successfully', {
        type: 'success',
      });
      refresh();
    } else {
      notify('Failed to update card selection required', {
        type: 'error',
      });
    }
    setLoading(false);
  };

  return (
    <Grid item xs={12} sm={12} md={6} mt={1}>
      <Labeled label="Card Selection Required" style={{ display: 'ruby' }}>
        <Switch
          checked={cardSelectionRequired}
          onChange={handleCardSelectionRequired}
          disabled={loading}
        />
      </Labeled>
    </Grid>
  );
};
