import { useMemo, CSSProperties, useEffect, useState } from 'react';
import { useGetList, usePermissions } from 'react-admin';
import {
  useMediaQuery,
  Theme,
  Box,
  Grid,
  Stack,
  Skeleton,
  useTheme,
} from '@mui/material';
import { subDays, startOfDay } from 'date-fns';

import PriceWidget from './PriceWidget';
import NewCustomers from './NewCustomers';
import VolumeChart from './VolumeChart';

import { Customer, Order } from '../types';
import NodalBalance from './NodalBalance';
import { getFee, getProfit, getTaxInFee } from '../helpers';
import ProfitChart from './ProfitChart';
import { getStats } from '../modules/notes/notesApi'; // Import the new API function
import StatsWidget from './StatsWidget';
import OrderIcon from '@mui/icons-material/CurrencyRupee';
import PayeeIcon from '@mui/icons-material/AccountBalance';

import {
  NoteAlt,
  Person,
  CreditCard,
  Group,
  SvgIconComponent,
} from '@mui/icons-material';
import { useNavigate } from 'react-router';
import { loggedUser } from '../authProvider';

interface OrderStats {
  volume: number;
  revenue: number;
  tax: number;
  profit: number;
  fee: number;
  taxInFee: number;
  nbNewOrders: number;
  pendingOrders: Order[];
  totalPendingSettlement: number;
}

interface State {
  volume?: string;
  nbNewOrders?: number;
  pendingOrders?: Order[];
  recentOrders?: Order[];
  revenue?: string;
  tax?: string;
  profit?: string;
  fee?: string;
  taxInFee?: string;
  totalPendingSettlement?: string;
}

const styles = {
  flex: { display: 'flex' },
  flexColumn: { display: 'flex', flexDirection: 'column' },
  leftCol: { flex: 1, marginRight: '0.5em' },
  rightCol: { flex: 1, marginLeft: '0.5em' },
  singleCol: { marginTop: '1em', marginBottom: '1em' },
};

const Spacer = () => <span style={{ width: '1em' }} />;
const VerticalSpacer = () => <span style={{ height: '1em' }} />;

const Dashboard = () => {
  const { permissions } = usePermissions();
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));
  const aMonthAgo = useMemo(() => subDays(startOfDay(new Date()), 30), []);
  const theme = useTheme();

  const { data } = useGetList<Order>('orders', {
    filter: {
      createdAtGTE: aMonthAgo.toISOString(),
      paymentStatus: 'SETTLEMENT_COMPLETED',
    },
    sort: { field: 'createdAt', order: 'DESC' },
    pagination: { page: 1, perPage: 10000 },
  });

  // const { data: customers = [] } = useGetList<Customer>('customers', {
  //   filter: {
  //     createdAtGTE: aMonthAgo.toISOString(),
  //   },
  //   sort: { field: 'createdAt', order: 'DESC' },
  //   pagination: { page: 1, perPage: 10000 },
  // });

  const ordersCompleted = data?.filter(
    (d) => d.priceBreakUp?.feeBearer !== 'admin'
  );

  const { data: ordersReceived, isLoading: ordersLoading } = useGetList<Order>(
    'orders',
    {
      filter: {
        createdAtGTE: aMonthAgo.toISOString(),
        paymentStatus: 'RECEIVED',
      },
      sort: { field: 'createdAt', order: 'DESC' },
      pagination: { page: 1, perPage: 500 },
    }
  );

  // @ts-ignore
  const aggregationCompleted = useMemo<State>(() => {
    if (!ordersCompleted) return {};
    const aggregations = ordersCompleted.reduce(
      (stats: Partial<OrderStats>, order) => {
        (stats.volume as number) += order?.priceBreakUp?.totalAmount || 0;
        const tax = order?.priceBreakUp?.gstAmount || 0;
        const taxInFee = getTaxInFee(order);
        (stats.tax as number) += tax - taxInFee;
        (stats.revenue as number) +=
          order?.priceBreakUp?.serviceFeeAmount + tax || 0;

        (stats.profit as number) += getProfit(order);
        (stats.fee as number) += getFee(order) - taxInFee || 0;
        (stats.taxInFee as number) += taxInFee;

        return stats;
      },
      {
        volume: 0,
        revenue: 0,
        tax: 0,
        profit: 0,
        fee: 0,
        taxInFee: 0,
      }
    );
    return {
      volume: Math.round(aggregations.volume as number),
      revenue: Math.round(aggregations.revenue as number),
      profit: Math.round(aggregations.profit as number),
      tax: Math.round(aggregations.tax as number),
      fee: Math.round(aggregations.fee as number),
      taxInFee: Math.round(aggregations.taxInFee as number),
    };
  }, [ordersCompleted]);

  // @ts-ignore
  const aggregationReceived = useMemo<State>(() => {
    if (!ordersReceived) return {};
    const aggregations = ordersReceived.reduce(
      (stats: Partial<OrderStats>, order) => {
        (stats.nbNewOrders as number) += 1;
        (stats.totalPendingSettlement as number) +=
          order?.priceBreakUp?.settlementAmount || 0;
        return stats;
      },
      {
        nbNewOrders: 0,
        pendingOrders: [],
        totalPendingSettlement: 0,
      }
    );
    return {
      nbNewOrders: aggregations.nbNewOrders,
      pendingOrders: aggregations.pendingOrders,
      totalPendingSettlement: aggregations.totalPendingSettlement,
    };
  }, [ordersCompleted]);

  const { volume, revenue, tax, profit, fee, taxInFee } = aggregationCompleted;
  const { totalPendingSettlement } = aggregationReceived;

  // Update the state type to match the API response
  const [stats, setStats] = useState<{
    orders: { totalNotes: number; unreadNotes: number };
    payees: { totalNotes: number; unreadNotes: number };
    cards: { totalNotes: number; unreadNotes: number };
    customers: { totalNotes: number; unreadNotes: number };
  } | null>(null);
  const noteSections = [
    { id: 'orders', icon: OrderIcon },
    { id: 'payees', icon: PayeeIcon },
    { id: 'cards', icon: CreditCard },
    { id: 'customers', icon: Group },
  ];

  const [statsLoading, setStatsLoading] = useState(true);

  // Fetch the stats data
  useEffect(() => {
    const fetchStats = async () => {
      if (!loggedUser?.userId) return;
      const data = await getStats();
      setStats(data);
      setStatsLoading(false); // Set loading to false after fetching stats
    };

    fetchStats();
  }, [loggedUser?.userId]);

  const navigate = useNavigate();

  const loading = ordersLoading || statsLoading;

  return (
    <Box
      m={2}
      ml={0}
      sx={{
        [theme.breakpoints.down('sm')]: {
          marginLeft: 4,
        },
      }}
    >
      <Grid container xs={12} spacing={2} alignItems={'baseline'}>
        {/* Left Column */}
        <Grid container item spacing={2} xs={12} md={6}>
          {/* Price Widgets */}
          <Grid item xs={12} md={6}>
            {loading ? (
              <Skeleton
                variant="rectangular"
                height={100}
                sx={{ borderRadius: 1 }}
              />
            ) : (
              <PriceWidget value={volume} title="Monthly Volume" />
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            {loading ? (
              <Skeleton
                variant="rectangular"
                height={100}
                sx={{ borderRadius: 1 }}
              />
            ) : (
              <PriceWidget value={revenue} title="Monthly Revenue" />
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            {loading ? (
              <Skeleton
                variant="rectangular"
                height={100}
                sx={{ borderRadius: 1 }}
              />
            ) : (
              <NodalBalance />
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            {loading ? (
              <Skeleton
                variant="rectangular"
                height={100}
                sx={{ borderRadius: 1 }}
              />
            ) : (
              <PriceWidget
                value={totalPendingSettlement}
                title="Pending Settlements"
              />
            )}
          </Grid>

          {/* Advanced Numbers Widgets */}
          {permissions?.includes('advanced_numbers') && (
            <>
              {/* Monthly Profit */}
              <Grid item xs={12} md={6}>
                {loading ? (
                  <Skeleton
                    variant="rectangular"
                    height={100}
                    sx={{ borderRadius: 1 }}
                  />
                ) : (
                  <PriceWidget value={profit} title="Monthly Profit" />
                )}
              </Grid>

              {/* Monthly PG Fee */}
              <Grid item xs={12} md={6}>
                {loading ? (
                  <Skeleton
                    variant="rectangular"
                    height={100}
                    sx={{ borderRadius: 1 }}
                  />
                ) : (
                  <PriceWidget value={fee} title="Monthly PG Fee" />
                )}
              </Grid>

              {/* Monthly Tax */}
              <Grid item xs={12} md={6}>
                {loading ? (
                  <Skeleton
                    variant="rectangular"
                    height={100}
                    sx={{ borderRadius: 1 }}
                  />
                ) : (
                  <PriceWidget value={tax} title="Monthly Tax" />
                )}
              </Grid>

              {/* Monthly PG Tax */}
              <Grid item xs={12} md={6}>
                {loading ? (
                  <Skeleton
                    variant="rectangular"
                    height={100}
                    sx={{ borderRadius: 1 }}
                  />
                ) : (
                  <PriceWidget value={taxInFee} title="Monthly PG Tax" />
                )}
              </Grid>
            </>
          )}

          {/* Volume Chart */}
          <Grid item xs={12}>
            {loading ? (
              <Skeleton
                variant="rectangular"
                height={300}
                sx={{ borderRadius: 1 }}
              />
            ) : (
              <VolumeChart orders={ordersCompleted} />
            )}
          </Grid>
          {permissions?.includes('advanced_numbers') && (
            <Grid item xs={12}>
              {loading ? (
                <Skeleton
                  variant="rectangular"
                  height={300}
                  sx={{ borderRadius: 1 }}
                />
              ) : (
                <ProfitChart orders={ordersCompleted} />
              )}
            </Grid>
          )}
        </Grid>

        {/* Right Column */}
        <Grid container item xs={12} md={6} spacing={2}>
          {/* Stats Widgets */}
          {noteSections.map((item: { id: string; icon: SvgIconComponent }) => (
            <Grid item xs={12} md={6} key={item.id}>
              {loading ? (
                <Skeleton
                  variant="rectangular"
                  height={100}
                  sx={{ borderRadius: 1 }}
                />
              ) : (
                <div
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    navigate(item.id, {
                      state: {
                        from: 'dashboard',
                      },
                    });
                  }}
                >
                  <StatsWidget
                    icon={item.icon}
                    value={Number(
                      stats?.[item.id as keyof typeof stats]?.unreadNotes
                    )}
                    title={`${item.id} Notes`}
                  />
                </div>
              )}
            </Grid>
          ))}
          <Grid item xs={12} md={6}>
            {loading ? (
              <Skeleton
                variant="rectangular"
                height={100}
                sx={{ borderRadius: 1 }}
              />
            ) : (
              <NewCustomers />
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            {loading ? (
              <Skeleton
                variant="rectangular"
                height={100}
                sx={{ borderRadius: 1 }}
              />
            ) : (
              <NewCustomers value={1} />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dashboard;
