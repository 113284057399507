import {
  Show,
  SimpleShowLayout,
  TextField,
  DateField,
  NumberField,
  Labeled,
  useRecordContext,
  useNotify,
  useRefresh,
} from 'react-admin';
import {
  Grid,
  Typography,
  Stack,
  Button,
  TextField as MuiTextField,
  Box,
} from '@mui/material';
import { useState, useEffect } from 'react';
import CustomerReferenceField from '../../customers/CustomerReferenceField';

const ApiUserLimitsTitle = () => {
  const record = useRecordContext();
  return <span>API User Limit {record ? `"${record.userId}"` : ''}</span>;
};

const ExtraRateLimitField = () => {
  const record = useRecordContext();
  const notify = useNotify();
  const refresh = useRefresh();
  const [extraRateLimit, setExtraRateLimit] = useState(
    record?.extraRateLimit || ''
  );
  const [updating, setUpdating] = useState(false);

  useEffect(() => {
    if (record?.extraRateLimit) {
      setExtraRateLimit(record.extraRateLimit.toString());
    }
  }, [record]);

  const handleUpdate = async () => {
    try {
      setUpdating(true);
      const response = await fetch(
        `${import.meta.env.VITE_API_URL}/user/rateLimits/${record.id}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('auth')}`,
          },
          body: JSON.stringify({ extraRateLimit: parseInt(extraRateLimit) }),
        }
      );

      if (response.ok) {
        notify('Extra rate limit updated successfully', { type: 'success' });
        refresh();
      } else {
        notify('Error updating extra rate limit', { type: 'error' });
      }
    } catch (error) {
      notify('Error updating extra rate limit', { type: 'error' });
    } finally {
      setUpdating(false);
    }
  };

  return (
    <Box sx={{ backgroundColor: 'background.paper', borderRadius: 1 }}>
      <Stack spacing={2} maxWidth="300px">
        <Labeled source="extraRateLimit" label="Extra Rate Limit">
          <MuiTextField
            type="number"
            value={extraRateLimit}
            onChange={(e) => setExtraRateLimit(e.target.value)}
            inputProps={{ min: 0 }}
            size="small"
            fullWidth
          />
        </Labeled>
        <Button
          variant="contained"
          onClick={handleUpdate}
          disabled={
            updating || parseInt(extraRateLimit) === record?.extraRateLimit
          }
          size="small"
          // fullWidth
        >
          Update
        </Button>
      </Stack>
    </Box>
  );
};

const ApiUserLimitsShow = () => {
  return (
    <Show title={<ApiUserLimitsTitle />}>
      <SimpleShowLayout>
        <Grid container spacing={2} md={8}>
          <Grid item md={8} minWidth="100%">
            <Typography variant="h6" gutterBottom>
              User Rate Limit Details
            </Typography>
            <Grid container spacing={2}>
              {/* Left Column - Read-only Fields */}
              <Grid item xs={12} md={6}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <CustomerReferenceField link="show" />
                  </Grid>
                  <Grid item xs={12}>
                    <Labeled source="date">
                      <TextField source="date" />
                    </Labeled>
                  </Grid>
                  <Grid item xs={12}>
                    <Labeled source="rateLimit">
                      <NumberField source="rateLimit" />
                    </Labeled>
                  </Grid>
                  <Grid item xs={12}>
                    <Labeled source="createdAt" label="First Hit">
                      <DateField
                        source="createdAt"
                        showTime
                        options={{
                          timeZone: 'Asia/Kolkata',
                          hour12: true,
                          hour: 'numeric',
                          minute: 'numeric',
                        }}
                      />
                    </Labeled>
                  </Grid>
                  <Grid item xs={12}>
                    <Labeled source="updatedAt" label="Last Hit">
                      <DateField
                        source="updatedAt"
                        showTime
                        options={{
                          timeZone: 'Asia/Kolkata',
                          hour12: true,
                          hour: 'numeric',
                          minute: 'numeric',
                        }}
                      />
                    </Labeled>
                  </Grid>
                </Grid>
              </Grid>

              {/* Right Column - Editable Fields */}
              <Grid item xs={12} md={6}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="subtitle2" gutterBottom>
                      Update Limits
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <ExtraRateLimitField />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </SimpleShowLayout>
    </Show>
  );
};

export default ApiUserLimitsShow;
