import KYCIcon from '@mui/icons-material/AccountBalance';

import KYCList from './KYCList';

const resource = {
  list: KYCList,
  icon: KYCIcon,
  // recordRepresentation: (record: any) => record.transactionId,
};

export default resource;
