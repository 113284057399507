import { AuthProvider } from 'react-admin';
import { fetchUtils } from 'react-admin';
import { apiUrl } from './constants';
import { roles, userRoles } from './roles';

const httpClient = fetchUtils.fetchJson;

export let loggedUser = {} as {
  userId?: string;
  firstName?: string;
  lastName?: string;
};

const authProvider: AuthProvider = {
  login: ({ mobile, password }) => {
    return httpClient(`${apiUrl}/auth/login`, {
      method: 'POST',
      body: JSON.stringify({ mobile, password }),
    }).then(({ json: data }) => {
      if (data.code === 'MSG_0057') {
        localStorage.setItem('auth', data.token);
        localStorage.setItem('auth_mobile', mobile);
      }
    });
  },
  logout: () => {
    localStorage.removeItem('auth');
    return Promise.resolve();
  },
  checkError: ({ status }) => {
    if (status === 401 || status === 403) {
      localStorage.removeItem('auth');
      return Promise.reject();
    }
    return Promise.resolve();
  },
  checkAuth: () =>
    localStorage.getItem('auth') ? Promise.resolve() : Promise.reject(),
  getPermissions: () => {
    const mobile = localStorage.getItem('auth_mobile');
    if (!mobile) {
      localStorage.removeItem('auth');
      return Promise.reject();
    }

    return Promise.resolve(userRoles[mobile] || roles.basic);
  },
  getIdentity: () => {
    const token = localStorage.getItem('auth') as string;

    return httpClient(`${apiUrl}/auth/profile`, {
      method: 'GET',
      headers: new Headers({
        Authorization: token,
      }),
    }).then(({ json }) => {
      loggedUser = {
        userId: json.data.userId,
        firstName: json.data?.personalDetails?.firstName,
        lastName: json.data?.personalDetails?.lastName,
      };
      console.log(json.data, 'json.data');
      return {
        id: json.data.id,
        fullName: `${json.data?.personalDetails?.firstName} ${json.data?.personalDetails?.lastName}`,
        avatar: json.data.profileImage,
        userId: json.data.userId,
      };
    });
  },
};

export default authProvider;
