import { Create, SimpleForm } from 'react-admin';
import { useCreateController } from 'react-admin';

import { CardProviderInputs } from './CardProviderEdit';
import { uploadImage } from '../../imageUploadDelete';

const CardProviderCreate = () => {
  const { save } = useCreateController();

  const handleSubmit = async (data: any, event: any) => {
    if (!save) return;
    event?.preventDefault();

    save({
      isActive: data.enabled || true,
      cardProviderName: data.name,
      cardProviderId: data.id,
      ...(data?.logo?.rawFile && { logo: await uploadImage(data.logo) }),
    });
  };

  return (
    <Create title="Create a card provider">
      <SimpleForm onSubmit={handleSubmit}>
        <CardProviderInputs />
      </SimpleForm>
    </Create>
  );
};

export default CardProviderCreate;
