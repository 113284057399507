import {
  Show,
  SimpleShowLayout,
  TextField,
  Labeled,
  useRecordContext,
} from 'react-admin';
import { Grid, Typography, Stack } from '@mui/material';
import NoteReadBy from '../modules/notes/noteReadBy';
import { NoteCreatedBy } from '../modules/notes/noteCreatedBy';

export const NoteShowContent = ({ note }: { note?: any }) => {
  const record = note || useRecordContext();
  console.log(record, 'pppp');

  if (!record) return null;
  return (
    <Stack spacing={2}>
      <Typography variant="h6" gutterBottom>
        Note Details
      </Typography>
      <Stack direction="row" spacing={2}>
        <Labeled label="Note Id">
          <TextField source="itemId" />
        </Labeled>
      </Stack>
      <Stack direction="row" spacing={2}>
        <Labeled label="Note">
          <TextField source="text" />
        </Labeled>
      </Stack>
      {record.readBy.length > 0 && (
        <Stack direction="row" spacing={2}>
          <Labeled label="Read By">
            <ReadBy readBy={record.readBy} />
          </Labeled>
        </Stack>
      )}
    </Stack>
  );
};

const ReadBy = ({ readBy = [] }) => {
  return (
    <Stack direction="column" spacing={1} flexWrap="wrap">
      {readBy?.map(({ user, readTimestamp }: any) => (
        <NoteCreatedBy
          key={user.userId}
          data={{
            firstName: user?.firstName,
            lastName: user?.lastName,
            createdAt: readTimestamp,
          }}
        />
      ))}
    </Stack>
  );
};

const NoteShow = (props: any) => {
  return (
    <Show>
      <SimpleShowLayout>
        <Grid container width={{ xs: '100%', xl: 800 }} spacing={2}>
          <Grid item md={12}>
            <NoteShowContent note={props.note} />
          </Grid>
        </Grid>
      </SimpleShowLayout>
    </Show>
  );
};

export default NoteShow;
