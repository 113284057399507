import BankCreate from './BankCreate';
import BankEdit from './BankEdit';
import BankList from './BankList';

const resource = {
  list: BankList,
  edit: BankEdit,
  create: BankCreate,
};

export default resource;
