import {
  BooleanInput,
  Edit,
  FormDataConsumer,
  minValue,
  required,
  SimpleForm,
  TextInput,
  useEditController,
  useRefresh,
} from 'react-admin';
import { RangeTypeInputs } from './RangeTypeCreate';

export const rangeTypeValidations = {
  minAmount: (formData: any = {}) => {
    return [required()];
  },
  maxAmount: (formData: any = {}) => {
    return [
      required(),
      minValue(+formData.minAmount, 'value should be greater than minValue'),
    ];
  },
};

const RangeTypeEdit = () => {
  const { save } = useEditController();

  const handleSubmit = async (data: any, event: any) => {
    if (!save) return;
    event?.preventDefault();

    save({
      minAmount: data.minAmount,
      maxAmount: data.maxAmount,
      rangeTypeDesc: data.name,
      isActive: data.enabled,
    });
  };

  return (
    <Edit redirect="false">
      <SimpleForm onSubmit={handleSubmit}>
        <RangeTypeInputs />
      </SimpleForm>
    </Edit>
  );
};

export default RangeTypeEdit;
