import {
  BooleanInput,
  Edit,
  FormDataConsumer,
  SimpleForm,
  TextInput,
  useEditController,
  useRefresh,
} from 'react-admin';
import { deleteImage, LogoUpload, uploadImage } from '../../imageUploadDelete';
import { useParams } from 'react-router';
import { Grid } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useEffect } from 'react';

export const CardProviderInputs = ({ edit }: { edit?: boolean }) => {
  const { setValue, getValues } = useFormContext();

  return (
    <FormDataConsumer>
      {({ formData, ...rest }) => (
        <Grid container>
          <Grid item xs={12} sm={3} minWidth={300}>
            <div
              style={{
                display: 'flex',
                flexDirection: edit ? 'column' : 'column-reverse',
              }}
            >
              <RenderId formData={formData} edit={edit} />
              <TextInput label="Title" source="name" fullWidth />
            </div>
            <LogoUpload />
          </Grid>
        </Grid>
      )}
    </FormDataConsumer>
  );
};

const RenderId = ({ formData, edit }: { formData: any; edit?: boolean }) => {
  const { setValue, getValues } = useFormContext();
  useEffect(() => {
    if (!edit)
      setValue('id', formData.name?.toLowerCase()?.split(' ').join('-'));
  }, [formData.name]);
  return <TextInput label="ID" source="id" disabled={edit} fullWidth />;
};

const CardProviderEdit = () => {
  const { save } = useEditController({ mutationMode: 'pessimistic' });
  const refresh = useRefresh();
  const { id } = useParams<{ id: string }>();
  const { record } = useEditController({ id });

  const handleSubmit = async (data: any, event: any) => {
    if (!save) return;
    event?.preventDefault();

    if (record.logo !== data.logo && record.logo) {
      await deleteImage(record.logo);
    }
    await save({
      isActive: data.enabled,
      cardProviderName: data.name,
      //to delete image if logo is not changed
      ...(record.logo !== data.logo && record.logo && { logo: '' }),
      //to upload image if logo is changed
      ...(data?.logo?.rawFile && { logo: await uploadImage(data.logo) }),
    });
    refresh();
  };

  return (
    <Edit redirect="false">
      <SimpleForm onSubmit={handleSubmit}>
        <CardProviderInputs edit={true} />
      </SimpleForm>
    </Edit>
  );
};

export default CardProviderEdit;
