import {
  AutocompleteInput,
  DateInput,
  NumberInput,
  ReferenceInput,
  SearchInput,
  SelectInput,
  useGetList,
} from 'react-admin';

import { useEffect, useState } from 'react';
import { apiUrl, nodeAPIUrl } from '../../constants';

export const usePaymentGateways = () => {
  const [paymentGateways, setPaymentGateways] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await fetch(apiUrl + '/paymentGateWay', {
          headers: {
            Authorization: localStorage.getItem('auth') || '',
          },
        });
        const jsonData = await res.json();
        setPaymentGateways(JSON.parse(jsonData.data));
        setError(null);
      } catch (err) {
        setError(
          err instanceof Error
            ? err
            : new Error('Failed to fetch payment gateways')
        );
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return { paymentGateways, loading, error };
};

export const useGetFilters = () => {
  const { paymentGateways } = usePaymentGateways();
  return { paymentGateways };
};

export const merchantCodesFilters = ({ paymentGateways }: any) => [
  <NumberInput label="Fee Above" source="feeGTE" />,
  <NumberInput label="Fee Below" source="feeLTE" />,
  <SelectInput
    label="Payment Gateway"
    source="paymentGatewayId"
    choices={paymentGateways}
    optionValue="pgId"
    optionText={(choice?: any) => `${choice?.pgName}`}
  />,
];
