import * as React from 'react';
import {
  PaginationProps,
  ReferenceField,
  ReferenceFieldProps,
} from 'react-admin';

import FullNameField from './FullNameField';

const CustomerReferenceField = (
  props: Omit<ReferenceFieldProps, 'reference' | 'children' | 'source'> & {
    source?: string;
    pagination?: PaginationProps;
  }
) => {
  console.log('pagination', props.pagination, props);
  return (
    <ReferenceField
      source="userId"
      label="Customer"
      reference="customers"
      {...props}
    >
      <FullNameField />
    </ReferenceField>
  );
};

CustomerReferenceField.defaultProps = {
  source: 'userId',
};

export default CustomerReferenceField;
