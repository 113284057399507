import { Card, CardContent } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import {
  FilterList,
  FilterListItem,
  FilterLiveSearch,
  SavedQueriesList,
  useGetList,
} from 'react-admin';
import { useGetFilters } from './MappingsFilters';
const MappingsListAside = () => {
  const {
    settlementTypes,
    rangeTypes,
    cardTypes,
    cardBankTypes,
    paymentTypes,
    merchantCodes,
  } = useGetFilters();

  return (
    <Card
      sx={{
        order: -1,
        flex: '0 0 15em',
        mr: 2,
        mt: 6,
        alignSelf: 'flex-start',
        height: '85vh',
        overflowY: 'auto',
      }}
    >
      <CardContent sx={{ pt: 1 }}>
        <SavedQueriesList />

        <FilterList label="Payment Type" icon={<CheckCircleOutlineIcon />}>
          {paymentTypes.map((paymentType) => (
            <FilterListItem
              label={paymentType.desc}
              value={{ paymentTypeId: paymentType.id }}
            />
          ))}
        </FilterList>

        <FilterList label="Settlement Type" icon={<AccessTimeIcon />}>
          {settlementTypes.map((settlementType) => (
            <FilterListItem
              label={settlementType.description}
              value={{ settlementTypeId: settlementType.settlementTypeId }}
            />
          ))}
        </FilterList>

        <FilterList label="Card Type" icon={<CurrencyRupeeIcon />}>
          {cardTypes.map((cardType) => (
            <FilterListItem
              label={cardType.desc}
              value={{ cardTypeId: cardType.id }}
            />
          ))}
        </FilterList>

        <FilterList label="Bank Type" icon={<CurrencyRupeeIcon />}>
          {cardBankTypes.map((cardBankType) => (
            <FilterListItem
              label={cardBankType.desc}
              value={{ cardBankTypeId: cardBankType.id }}
            />
          ))}
        </FilterList>

        <FilterList label="Range Type" icon={<CurrencyRupeeIcon />}>
          {rangeTypes.map((rangeType) => (
            <FilterListItem
              label={rangeType.desc}
              value={{ rangeTypId: rangeType.id }}
            />
          ))}
        </FilterList>

        {/* <FilterList label="Merchant Code" icon={<CurrencyRupeeIcon />}>
          {merchantCodes.map((merchantCode) => (
            <FilterListItem
              label={merchantCode.details || merchantCode.merchantId}
              value={{ merchantCode: merchantCode.id }}
            />
          ))}
        </FilterList> */}
      </CardContent>
    </Card>
  );
};

export default MappingsListAside;
