import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/DriveFileRenameOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import { AvatarGroup, Box } from '@mui/material';
import LetterAvatar from './avatar';
import { NoteCreatedBy } from './noteCreatedBy';
const ITEM_HEIGHT = 48;

export default function NoteReadBy({ readBy = [] }: any) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <Box
        sx={{
          '.MuiAvatarGroup-avatar': {
            fontSize: 18 * 0.65,
            height: 40 * 0.65,
            width: 40 * 0.65,
          },
        }}
      >
        <AvatarGroup
          onClick={handleClick}
          sx={{ cursor: 'pointer' }}
          total={readBy.length}
        >
          {readBy?.slice(0, 2).map(({ user }: any, index: number) => (
            <LetterAvatar
              key={index}
              name={user.firstName + '' + user.lastName}
              scale={0.7}
            />
          ))}
        </AvatarGroup>
      </Box>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        slotProps={{
          paper: {
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: '20ch',
            },
          },
        }}
      >
        {readBy?.map(({ user, readTimestamp }: any) => (
          <MenuItem
            key={user.title}
            onClick={() => {
              handleClose();
            }}
            sx={{ svg: { pr: 2 } }}
          >
            <NoteCreatedBy
              data={{
                firstName: user?.firstName,
                lastName: user?.lastName,
                createdAt: readTimestamp,
              }}
            />
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
}
