import { Theme, useMediaQuery } from '@mui/material';
import {
  BooleanField,
  Datagrid,
  FunctionField,
  List,
  ReferenceField,
  TextField,
} from 'react-admin';
import CardBankTypesAside from './CardBankTypesAside';

const CardBankTypesList = () => {
  return (
    <List exporter={false} aside={<CardBankTypesAside />}>
      <Datagrid bulkActionButtons={false} rowClick="edit">
        <TextField label="Name" source="name" />
        <TextField label="Desc" source="desc" />
        <TextField label="Card Type" source="cardType" />
        <BooleanField label="Enabled" source="enabled" />
      </Datagrid>
    </List>
  );
};

export default CardBankTypesList;
