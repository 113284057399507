import React, { useState } from 'react';

import { createContext, useContext } from 'react';

// Create a context to share the state across components
export const NotesDrawerContext = createContext<{
  isOpen: boolean;
  openNotesDrawer: () => void;
  closeNotesDrawer: () => void;
  toggleNotesDrawer: () => void;
}>({
  isOpen: false,
  openNotesDrawer: () => {},
  closeNotesDrawer: () => {},
  toggleNotesDrawer: () => {},
});

// Provider component
export const NotesDrawerProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const openNotesDrawer = () => setIsOpen(true);
  const closeNotesDrawer = () => setIsOpen(false);
  const toggleNotesDrawer = () => setIsOpen(!isOpen);

  return (
    <NotesDrawerContext.Provider
      value={{
        isOpen,
        openNotesDrawer,
        closeNotesDrawer,
        toggleNotesDrawer,
      }}
    >
      {children}
    </NotesDrawerContext.Provider>
  );
};

// Hook to use the drawer state
export const useNotesDrawer = () => {
  const context = useContext(NotesDrawerContext);
  if (!context) {
    throw new Error('useNotesDrawer must be used within a NotesDrawerProvider');
  }
  return context;
};
