import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
} from '@mui/material';
import { Create, FormDataConsumer, SimpleForm, TextInput } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { apiUrl } from '../constants';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState } from 'react';
const PayeeCreate = () => {
  return (
    <Create title="Create a Payee">
      <SimpleForm>
        <FormDataConsumer>
          {({ formData, ...rest }) => (
            <>
              <IFSCValidationInputs formData={formData} />
              <Grid container sm={6} columnSpacing={2} mt={2}>
                <Grid item sm={12}>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      Additional Fields
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container sm={12} columnSpacing={2}>
                        <Grid item xs={12} sm={6}>
                          <TextInput
                            source="mobileNo"
                            type="number"
                            label="Mobile"
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextInput source="payeeNickName" fullWidth />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <TextInput
                            source="remarks"
                            multiline
                            rows={3}
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              </Grid>
            </>
          )}
        </FormDataConsumer>
      </SimpleForm>
    </Create>
  );
};

const IFSCValidationInputs = ({ formData }: any) => {
  const { payeeAccountNo, payeeAccountIfscCode, name } = formData;
  const { setValue } = useFormContext();
  const [loading, setLoading] = useState(false);

  const validate = async () => {
    setLoading(true);
    const res = await fetch(`${apiUrl}/payee/validate/account`, {
      method: 'POST',
      body: JSON.stringify({
        payeeAccountNo,
        payeeAccountIfscCode,
        userId: 'PAYMADI5889',
      }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('auth') || '',
      },
    }).then((res) => res.json());
    setLoading(false);
    console.log(res, 'kk');
  };

  return (
    <Grid container columnSpacing={2} sm={6} sx={{ alignItems: 'center' }}>
      <Grid item xs={12} sm={6}>
        <TextInput
          source="payeeAccountNo"
          type="number"
          label="Payee Account Number"
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInput source="payeeAccountIfscCode" fullWidth />
      </Grid>
      <Grid item xs={12} sm={12} mt={-1} mb={1}>
        <Button onClick={validate} variant="contained" disabled={loading}>
          {loading ? 'Validating...' : 'Validate'}
        </Button>
      </Grid>
    </Grid>
  );
};

export default PayeeCreate;

// {
//   "userId": "string",
//   "payeeAccountNo": "string",--
//   "payeeAccountIfscCode": "string",---
//   "payeeAccountHolderName": "string",---
//   "payeeBankName": "string",----no
//   "payeeVpa": "string",---no
//   "mobileNo": "string",--
//   "remarks": "string",---
//   "payeeNickName": "string",---
//   "payeeColor": "string",
//   "payeeAccountType": "BANK"
// }
