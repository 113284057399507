import {
  SelectInput,
  SimpleForm,
  TextInput,
  useEditController,
} from 'react-admin';
import { useGetFilters } from './MerchantCodesFilters';

const MerchantCodeForm = () => {
  const { save } = useEditController();
  const { paymentGateways } = useGetFilters();

  const handleSubmit = (data: any, event: any) => {
    if (!save) return;
    event?.preventDefault();
    save({
      merchantId: data.merchantId,
      clientKey: data.clientKey,
      secretKey: data.secretKey,
      paymentGateway: data.pgName,
    });
  };

  return (
    <SimpleForm onSubmit={handleSubmit}>
      <SelectInput
        source="pgName"
        label="Payment Gateway"
        choices={paymentGateways.map((pg: any) => ({
          id: pg.pgName,
          name: pg.pgName,
        }))}
        optionText="name"
        optionValue="id"
      />
      <TextInput source="merchantId" />
      <TextInput source="clientKey" fullWidth />
      <TextInput source="secretKey" fullWidth />
    </SimpleForm>
  );
};

export default MerchantCodeForm;
