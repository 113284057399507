import PayeeIcon from '@mui/icons-material/AccountBalance';

import PayeeList from './PayeeList';
import PayeeShow from './PayeeShow';
import PayeeCreate from './PayeeCreate';

const resource = {
  list: PayeeList,
  show: PayeeShow,
  icon: PayeeIcon,
  recordRepresentation: (record: any) => record.accountHolderName,
  create: PayeeCreate,
};

export default resource;
