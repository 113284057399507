import { Card, CardContent } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import { FilterList, FilterListItem, useGetList } from 'react-admin';
import { useGetFilters } from './MerchantCodesFilters';

const MerchantCodesAside = () => {
  const { paymentGateways } = useGetFilters();
  return (
    <Card
      sx={{
        order: -1,
        flex: '0 0 15em',
        mr: 2,
        mt: 6,
        alignSelf: 'flex-start',
        height: '85vh',
        overflowY: 'auto',
      }}
    >
      <CardContent sx={{ pt: 1 }}>
        <FilterList label="Payment Gateway" icon={<CheckCircleOutlineIcon />}>
          {paymentGateways.map((paymentGateway: any) => (
            <FilterListItem
              label={paymentGateway.pgName}
              value={{ paymentGatewayId: paymentGateway.pgId }}
            />
          ))}
        </FilterList>
      </CardContent>
    </Card>
  );
};

export default MerchantCodesAside;
