import { useEffect, useState } from 'react';
import { createNote, deleteNote, updateNote, updateReadNote } from './notesApi';
import { useNotify, useStore } from 'react-admin';
import {
  alpha,
  AvatarGroup,
  Box,
  Button,
  Fab,
  IconButton,
  InputBase,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { formatDistanceStrict } from 'date-fns';
import LetterAvatar from './avatar';
import CheckIcon from '@mui/icons-material/Check';
import NoteMenu from './noteMenu';

import EditIcon from '@mui/icons-material/DriveFileRenameOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import NoteReadBy from './noteReadBy';
import { NoteCreatedBy } from './noteCreatedBy';
import { loggedUser } from '../../authProvider';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate } from 'react-router';
const NoteBox = ({ note, index, callback, deleteNewlyAddedNote }: any) => {
  const isNewNote = note._id == -1;
  const theme = useTheme();
  const { text, _id, createdBy, readBy } = note;
  const [noteText, setNoteText] = useState(note.text);
  const [isSaving, setIsSaving] = useState(false);
  const [edit, setEdit] = useState(isNewNote);
  const notify = useNotify();
  const navigate = useNavigate();

  const [[section, itemId], setCurrentPath] = useStore<any>('currentPath', []);
  const addNote = () => {
    setIsSaving(true);
    createNote({ text: noteText as string, section, itemId }).then((data) => {
      callback();
      setIsSaving(false);
      setEdit(false);
      notify('Note saved successfully', {
        type: 'success',
      });
    });
  };
  const saveNote = () => {
    setIsSaving(true);
    updateNote({ text: noteText as string, noteId: _id }).then((data) => {
      setEdit(false);
      setIsSaving(false);
      notify('Note updated successfully', {
        type: 'success',
      });
      callback();
    });
  };

  useEffect(() => {
    setEdit(note._id == -1);
  }, []);

  const menuOptions = [
    {
      title: 'Edit',
      onClick: () => {
        setEdit(true);
      },
      icon: EditIcon,
    },
    {
      title: 'Delete',
      onClick: () => {
        deleteNote(_id).then(() => callback());
      },
      icon: DeleteIcon,
    },
  ];

  const setReadDone = () => {
    updateReadNote({ noteIds: [note._id] }).then(() => {
      callback();
    });
  };
  const isOwner = createdBy?.userId == loggedUser?.userId;
  const readDone =
    isOwner ||
    readBy?.some(({ user }: any) => user.userId == loggedUser?.userId);

  const isNavigationSecionEnabled = !itemId;

  return (
    <Box
      sx={{
        //   color: 'primary',
        // border: 1,
        borderStyle: 'solid',
        borderColor: theme.palette.divider,

        borderRadius: 0.5,
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          padding: 1.3,
          minHeight: 125,
          borderRadius: 0.5,
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          ...(isNavigationSecionEnabled
            ? {
                borderBottom: '1px solid',
                borderColor: theme.palette.divider,
              }
            : {}),
        }}
      >
        <Box sx={{ pr: 3, flex: 1 }}>
          <Box sx={{ position: 'absolute', right: 0, top: 0 }}>
            {edit ? (
              <IconButton
                sx={{}}
                onClick={() => {
                  setEdit(false);
                  setNoteText(note.text);
                  if (isNewNote) {
                    deleteNewlyAddedNote();
                  }
                }}
              >
                <CloseIcon />
              </IconButton>
            ) : isOwner ? (
              <NoteMenu options={menuOptions} />
            ) : null}
          </Box>

          {edit ? (
            <InputBase
              sx={{ padding: 0 }}
              multiline
              fullWidth
              value={noteText}
              autoFocus
              onChange={(e) => setNoteText(e.target.value)}
              rows={3}
              placeholder="Enter Note"
              onFocus={(e) =>
                e.currentTarget.setSelectionRange(
                  e.currentTarget.value.length,
                  e.currentTarget.value.length
                )
              }
            />
          ) : (
            text
          )}
        </Box>
        {/* 
        {!isNewNote && (
          <Fab
            size="small"
            // color="secondry"

            sx={{
              position: 'absolute',
              bottom: 0,
              right: 0,
              transform: 'scale(0.7)',
              boxShadow: 'none',
            }}
            onClick={() => {
              if (edit) {
                isNewNote ? addNote() : saveNote();
                return;
              }
              setNoteText(text);
              setEdit(true);
            }}
          >
            {edit ? <SaveIcon color="action" /> : <EditIcon color="action" />}
          </Fab>
        )}

        {!isNewNote && (
          <IconButton
            size="small"
            sx={{
              position: 'absolute',
              top: 1,
              right: 0,
              transform: 'scale(0.8)',
              boxShadow: 'none',
            }}
            onClick={() => {
              deleteNote(_id).then(() => callback());
            }}
          >
            {<DeleteIcon />}
          </IconButton>
        )} */}

        <Box sx={{ display: 'flex', gap: 1, pt: 1, alignItems: 'center' }}>
          <Box sx={{ flex: 1 }}>
            <NoteCreatedBy
              data={{
                firstName: note.createdBy?.firstName,
                lastName: note.createdBy?.lastName,
                createdAt: note.createdAt,
              }}
            />
          </Box>
          {edit ? (
            <Button
              disabled={isSaving}
              variant="contained"
              size="small"
              onClick={isNewNote ? addNote : saveNote}
              sx={{ marginLeft: 'auto', display: 'flex' }}
            >
              {'Save'}
            </Button>
          ) : (
            <>
              <NoteReadBy readBy={note.readBy} />
              {!readDone && (
                <Tooltip title={'Mark as Read'}>
                  <Fab
                    color="success"
                    size="small"
                    sx={{
                      width: 32,
                      height: 32,
                      minHeight: 32,
                      minWidth: 30,
                    }}
                    onClick={setReadDone}
                  >
                    <CheckIcon />
                  </Fab>
                </Tooltip>
              )}
            </>
          )}
        </Box>
      </Box>
      {isNavigationSecionEnabled && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',

            p: 1,
            ':hover': {
              backgroundColor: alpha(theme.palette.text.primary, 0.08),

              transition: 'all linear .1s',
            },
          }}
          onClick={() => {
            navigate(section + '/' + note.itemId);
          }}
        >
          <ArrowBackIosIcon sx={{ fontSize: '14px' }} />
          <Typography sx={{ textTransform: 'capitalize', fontSize: '14px' }}>
            View {section?.slice(0, -1)}
          </Typography>
          <Typography
            color={theme.palette.text.secondary}
            variant="body2"
            marginLeft={'auto'}
          >
            {note.itemId}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default NoteBox;
