import * as React from 'react';
import { Card, CardContent } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import KYCIcon from '@mui/icons-material/FingerprintOutlined';
import LocalOfferIcon from '@mui/icons-material/LocalOfferOutlined';
import {
  AutocompleteInput,
  DateInput,
  FilterList,
  FilterListItem,
  FilterLiveSearch,
  NullableBooleanInput,
  ReferenceInput,
  SavedQueriesList,
  TextInput,
  useGetList,
} from 'react-admin';
import {
  endOfYesterday,
  startOfWeek,
  subWeeks,
  startOfMonth,
  subMonths,
  addHours,
  startOfHour,
} from 'date-fns';
import { Customer } from '../types';
import { useGetFilters } from './UserConfigFilters';

// import segments from '../segments/data';

const Aside = () => {
  const { paymentTypes, settlementTypes, statusTypes } = useGetFilters();

  return (
    <Card
      sx={{
        display: {
          xs: 'none',
          md: 'block',
        },
        order: -1,
        flex: '0 0 15em',
        mr: 2,
        mt: 6,
        alignSelf: 'flex-start',
      }}
    >
      <CardContent sx={{ pt: 1 }}>
        <FilterLiveSearch hiddenLabel />
        <SavedQueriesList />

        <FilterList label="Created At" icon={<AccessTimeIcon />}>
          <FilterListItem
            label="Today"
            value={{
              createdAtGTE: endOfYesterday().toISOString(),
              createdAtLTE: undefined,
            }}
          />
          <FilterListItem
            label="This Week"
            value={{
              createdAtGTE: startOfWeek(new Date()).toISOString(),
              createdAtLTE: undefined,
            }}
          />
          <FilterListItem
            label="Last Week"
            value={{
              createdAtGTE: subWeeks(startOfWeek(new Date()), 1).toISOString(),
              createdAtLTE: startOfWeek(new Date()).toISOString(),
            }}
          />
          <FilterListItem
            label="This Month"
            value={{
              createdAtGTE: startOfMonth(new Date()).toISOString(),
              createdAtLTE: undefined,
            }}
          />
          <FilterListItem
            label="Last Month"
            value={{
              createdAtGTE: subMonths(
                startOfMonth(new Date()),
                1
              ).toISOString(),
              createdAtLTE: startOfMonth(new Date()).toISOString(),
            }}
          />
          <FilterListItem
            label="Earlier"
            value={{
              createdAtGTE: undefined,
              createdAtLTE: subMonths(
                startOfMonth(new Date()),
                1
              ).toISOString(),
            }}
          />
        </FilterList>

        <FilterList label="Payment Type" icon={<CheckCircleOutlineIcon />}>
          {paymentTypes.map((paymentType) => (
            <FilterListItem
              label={paymentType.desc}
              value={{ paymentTypeId: paymentType.id }}
            />
          ))}
        </FilterList>

        <FilterList label="Settlement Type" icon={<AccessTimeIcon />}>
          {settlementTypes.map((settlementType) => (
            <FilterListItem
              label={settlementType.description}
              value={{ settlementTypeId: settlementType.settlementTypeId }}
            />
          ))}
        </FilterList>

        <FilterList label="Status" icon={<CheckCircleOutlineIcon />}>
          {statusTypes.map((statusType) => (
            <FilterListItem
              label={statusType.name}
              value={{ active: statusType.status }}
            />
          ))}
        </FilterList>
      </CardContent>
    </Card>
  );
};

export default Aside;
