import { Theme, useMediaQuery } from '@mui/material';
import {
  BooleanField,
  Datagrid,
  FunctionField,
  ImageField,
  List,
  ReferenceField,
  TextField,
} from 'react-admin';

const BankList = () => {
  return (
    <List exporter={false}>
      <Datagrid bulkActionButtons={false} rowClick="edit">
        <TextField label="ID" source="id" />
        <TextField label="Name" source="name" />
        <ImageField
          label="Logo"
          source="logo"
          sx={{
            height: '30px',
            img: { height: '20px !important', width: 'auto !important' },
          }}
        />
      </Datagrid>
    </List>
  );
};

export default BankList;
