import { Datagrid, DateField, List, NumberField, TextField } from 'react-admin';
import CustomerReferenceField from '../../customers/CustomerReferenceField';
// import ApiUserLimitsAside from './ApiUserLimitsAside';

const ApiUserLimitsList = () => {
  return (
    <List
      resource="user/rateLimits"
      title="API User Limits"
      //   queryOptions={{
      //     meta: {
      //       fetch: 'https://test.paymadi.com/admin/v1/api/user/rateLimits',
      //     },
      //   }}
      //   aside={<ApiUserLimitsAside />}
    >
      <Datagrid bulkActionButtons={false} rowClick="show">
        <CustomerReferenceField
          link="show"
          // pagination={pagination}
        />

        {/* <TextField source="userId" label="User ID" /> */}
        <NumberField source="rateLimit" label="Hits" />
        <TextField source="date" />
        <DateField
          source="createdAt"
          showTime
          label="First Hit"
          options={{
            timeZone: 'Asia/Kolkata',
            hour12: true,
            hour: 'numeric',
            minute: 'numeric',
          }}
        />
        <DateField
          source="updatedAt"
          showTime
          label="Last Hit"
          options={{
            timeZone: 'Asia/Kolkata',
            hour12: true,
            hour: 'numeric',
            minute: 'numeric',
          }}
        />
      </Datagrid>
    </List>
  );
};

export default ApiUserLimitsList;
