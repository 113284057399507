import React from 'react';
import { Typography } from '@mui/material';
import CardWithIcon from './CardWithIcon';
import { SvgIconComponent } from '@mui/icons-material';

interface StatsWidgetProps {
  icon: SvgIconComponent;
  value: number;
  title: string;
  subtitle?: string;
}

const StatsWidget: React.FC<StatsWidgetProps> = ({
  icon,
  value,
  title,
  subtitle,
}) => {
  return (
    <CardWithIcon
      icon={icon}
      title={title}
      subtitle={
        <>
          {value.toLocaleString()}
          {/* {subtitle && (
            <Typography variant="caption" sx={{ display: 'block' }}>
              {subtitle}
            </Typography>
          )} */}
        </>
      }
    />
  );
};

export default StatsWidget;
