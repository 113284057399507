import { Theme, useMediaQuery } from '@mui/material';
import {
  BooleanField,
  Datagrid,
  DateField,
  List,
  TextField,
} from 'react-admin';
import MobileGrid from './MobileGrid';
import MerchantCodesAside from './MerchantCodesAside';
import { merchantCodesFilters, useGetFilters } from './MerchantCodesFilters';

const MerchantCodesList = () => {
  const { paymentGateways } = useGetFilters();
  return (
    <List
      exporter={false}
      hasCreate
      aside={<MerchantCodesAside />}
      filters={merchantCodesFilters({ paymentGateways })}
    >
      <Datagrid rowClick="show" bulkActionButtons={false}>
        <TextField label="ID" source="id" />
        <TextField source="details" />
        <TextField label="Merchant ID" source="merchantId" />
        <TextField source="fee" />
        <TextField label="Payment Gateway" source="pgName" />
        {/* <DateField label="Updated At" source="updatedAt" /> */}
      </Datagrid>
    </List>
  );
};

export default MerchantCodesList;
