import { Datagrid, DateField, List, NumberField, TextField } from 'react-admin';
// import ApiRateLimitsAside from './ApiRateLimitsAside';

const ApiRateLimitsList = () => {
  return (
    <List
      resource="apis/rateLimits"
      title="API Rate Limits"
      //   queryOptions={{
      //     meta: {
      //       fetch: 'https://test.paymadi.com/admin/v1/api/apis/rateLimits',
      //     },
      //   }}
      //   aside={<ApiRateLimitsAside />}
    >
      <Datagrid
        bulkActionButtons={false}
        rowClick="show"
        sx={{
          '& .RaDatagrid-headerCell': {
            width: '50%',
            textAlign: 'left',
          },
          '& .RaDatagrid-rowCell': {
            width: '50%',
            textAlign: 'left',
          },
        }}
      >
        <TextField source="apiPath" />
        <NumberField source="rateLimit" />
        {/* <DateField
          source="createdAt"
          showTime
          options={{
            timeZone: 'Asia/Kolkata',
            hour12: true,
            hour: 'numeric',
            minute: 'numeric',
          }}
        />
        <DateField
          source="updatedAt"
          showTime
          options={{
            timeZone: 'Asia/Kolkata',
            hour12: true,
            hour: 'numeric',
            minute: 'numeric',
          }}
        /> */}
      </Datagrid>
    </List>
  );
};

export default ApiRateLimitsList;
