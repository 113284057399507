import CardIcon from '@mui/icons-material/CreditCard';

import CardList from './CardList';
import Card from './Card';
import CardEdit from './CardEdit';

export default {
  list: CardList,
  show: Card,
  edit: CardEdit,
  icon: CardIcon,
};
