import { NumberInput, SearchInput, SelectInput, useGetList } from 'react-admin';

export const useGetFilters = () => {
  const { data: _settlementTypes = [] } = useGetList('settlementTypes', {
    pagination: { page: 1, perPage: 100 },
  });
  const settlementTypes = Array.from(
    new Set(_settlementTypes.map((st) => st.name))
  ).map((name) => _settlementTypes.find((st) => st.name === name));

  const { data: paymentTypes = [] } = useGetList('paymentTypes', {
    pagination: { page: 1, perPage: 100 },
  });

  const statusTypeChoices = [
    { id: 'enabled', name: 'Enabled', value: true },
    { id: 'disabled', name: 'Disabled', value: false },
  ];

  // const merchantCodes = _merchantCodes?.reduce((acc: any, item: any) => {
  //   if (!acc[item.merchantId]) {
  //     acc[item.merchantId] = item;
  //   }
  //   return acc;
  // });

  return {
    settlementTypes,
    paymentTypes,
    statusTypeChoices,
  };
};

export const settlementTypeFilters = ({
  settlementTypes,
  paymentTypes,
  statusTypeChoices,
}: any) => [
  <SelectInput
    label="Payment Type"
    source="paymentTypeId"
    choices={paymentTypes}
    optionText={(choice?: any) => (choice?.id ? `${choice?.desc}` : '')}
  />,
  <SelectInput
    label="Settlement Type"
    source="settlementTypeId"
    choices={settlementTypes.map((i: any) => ({
      id: i.settlementTypeId,
      name: i.description,
    }))}
    optionText={(choice?: any) => (choice?.id ? `${choice?.name}` : '')}
  />,
  <SelectInput
    label="Status"
    source="enabled"
    optionValue="value"
    choices={statusTypeChoices}
    optionText={(choice?: any) => `${choice?.name}`}
  />,
  <NumberInput label="Fee Above" source="feeGTE" />,
  <NumberInput label="Fee Below" source="feeLTE" />,
];
