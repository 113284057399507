import * as React from 'react';
import {
  DateField,
  Edit,
  Form,
  Labeled,
  NumberField,
  PrevNextButtons,
  ReferenceField,
  SelectInput,
  TextField,
  Toolbar,
  useEditController,
  useRecordContext,
  useTranslate,
  WrapperField,
  useInput,
  SaveButton,
  FunctionField,
  Link,
  usePermissions,
  useNotify,
  useRefresh,
  useLoading,
  ReferenceInput,
} from 'react-admin';
import { Link as RouterLink } from 'react-router-dom';
import {
  Card,
  CardContent,
  Box,
  Grid,
  Typography,
  Stack,
  Switch,
  FormControlLabel,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Checkbox,
  Divider,
  Input,
  Button,
} from '@mui/material';

import {
  Order,
  Customer,
  paymentStatusMap,
  paymentStatusTitles,
} from '../types';
import Totals from './Totals';
import OrderAside from './OrderAside';
import { renderPaymentStatus } from './OrderList';
import useNodalBalance from '../hooks/useNodalBalance';
import RazorpaySettle from '../dashboard/RazorpaySettle';
import CardDetails from './CardDetails';
import AddManualLink from '../dashboard/AddManualLink';
import AdjustServiceFee from '../dashboard/AdjustServiceFee';
import LinkProgress from '../dashboard/LinkProgress';
import { apiUrl } from '../constants';

const OrderEditToolbar = (props: any) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

const OrderEdit = () => (
  <Edit title={<OrderTitle />} component="div">
    <OrderForm />
  </Edit>
);

const OrderTitle = () => {
  const translate = useTranslate();
  const record = useRecordContext<Order>();
  return record ? (
    <span>
      {translate('resources.commands.title', {
        reference: record.clientReferenceId,
      })}
    </span>
  ) : null;
};

export const CustomerDetails = () => {
  const record = useRecordContext<Customer>();
  return (
    <div>
      <Typography
        component={RouterLink}
        color="primary"
        to={`/customers/${record?.id}/show`}
        style={{ textDecoration: 'none' }}
      >
        {record?.personalDetails.firstName} {record?.personalDetails.lastName}
      </Typography>
      <br />
      <Typography
        component={Link}
        color="primary"
        href={`mailto:${record?.personalDetails.email}`}
        style={{ textDecoration: 'none' }}
      >
        {record?.personalDetails.email}
      </Typography>
      <br />
      <Typography
        component={Link}
        color="primary"
        href={`tel:${record?.personalDetails.mobile}`}
        style={{ textDecoration: 'none' }}
      >
        {record?.personalDetails.mobile}
      </Typography>
    </div>
  );
};

const PayeeInfo = () => {
  const record = useRecordContext<Order>();
  return (
    <Stack spacing={1}>
      <Stack>
        <Typography variant="caption" color="text.secondary">
          Account Holder Name
        </Typography>
        <Typography>{record?.payeeInfo.accountHolderName}</Typography>
      </Stack>
      <Stack>
        <Typography variant="caption" color="text.secondary">
          Payee Account Type
        </Typography>
        <Typography>{record?.payeeInfo.payeeAccountType}</Typography>
      </Stack>
      {record?.payeeInfo.payeeAccountType === 'Bank' && (
        <>
          {/* <Stack>
            <Typography variant="caption" color="text.secondary">
              Bank Name
            </Typography>
            <Typography>
              {record?.payeeInfo.payeeBankName || 'missing'}
            </Typography>
          </Stack> */}
          <Stack>
            <Typography variant="caption" color="text.secondary">
              IFSC Code
            </Typography>
            <Typography>{record?.payeeInfo.accountIFSCCode}</Typography>
          </Stack>
          <Stack>
            <Typography variant="caption" color="text.secondary">
              Account Number
            </Typography>
            <Typography>{record?.payeeInfo.accountNo}</Typography>
          </Stack>
        </>
      )}
      {record?.payeeInfo.payeeAccountType === 'Upi' && (
        <>
          <Stack>
            <Typography variant="caption" color="text.secondary">
              UPI ID
            </Typography>
            <Typography>{record?.payeeInfo.vpa}</Typography>
          </Stack>
        </>
      )}
    </Stack>
  );
};

const SettleInput = ({ source }: { source: any }) => {
  const {
    field: { onChange, value },
  } = useInput({ source });

  if (
    value !== paymentStatusMap.RECEIVED &&
    value !== paymentStatusMap.SETTLEMENT_INITIATED
  )
    return null;

  return (
    <FormControlLabel
      control={
        <Switch
          checked={value === paymentStatusMap.SETTLEMENT_INITIATED}
          onChange={() => {
            onChange(
              value === paymentStatusMap.SETTLEMENT_INITIATED
                ? paymentStatusMap.RECEIVED
                : paymentStatusMap.SETTLEMENT_INITIATED
            );
          }}
        />
      }
      label="Settle Now"
    />
  );
};

const SettleMode = ({
  mode,
  setMode,
  source,
}: {
  mode: string;
  setMode: (m: string) => void;
  source: any;
}) => {
  const handleChange = (event: SelectChangeEvent) => {
    setMode(event.target.value as string);
  };

  const {
    field: { value },
  } = useInput({ source });

  if (
    value !== paymentStatusMap.SETTLEMENT_INITIATED &&
    value !== paymentStatusMap.REFUND_INITIATED
  )
    return null;

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel id="settle-mode">Mode</InputLabel>
        <Select
          labelId="settle-mode"
          id="settle-mode-select"
          value={mode}
          label="Mode"
          onChange={handleChange}
        >
          <MenuItem value="IMPS">IMPS</MenuItem>
          <MenuItem value="NEFT">NEFT</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};

const UtrNo = ({
  utr,
  setUtr,
  source,
}: {
  utr: string;
  setUtr: (m: string) => void;
  source: any;
}) => {
  const handleChange = (event: any) => {
    setUtr(event.target.value as string);
  };

  const {
    field: { value },
  } = useInput({ source });

  if (value !== paymentStatusMap.SETTLEMENT_COMPLETED) return null;

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel id="utr-no">UTR No</InputLabel>
        <Input value={utr} onChange={handleChange} />
      </FormControl>
    </Box>
  );
};

const ReceivedNotify = ({
  notify,
  setNotify,
  source,
}: {
  notify: boolean;
  setNotify: (m: boolean) => void;
  source: any;
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNotify(event.target.checked);
  };

  const {
    field: { value },
  } = useInput({ source });

  if (value !== paymentStatusMap.RECEIVED) return null;

  return (
    <Box
      sx={{
        minWidth: 120,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <FormControlLabel
        control={
          <Switch
            checked={notify}
            onChange={handleChange}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        }
        label="Notify User"
      />
    </Box>
  );
};

const AdvancedCheckbox = ({
  advancedActions,
  setAdvancedActions,
  source,
}: {
  advancedActions: any;
  setAdvancedActions: (m: any) => void;
  source: any;
}) => {
  const { permissions } = usePermissions();
  const { record, save } = useEditController();

  const {
    field: { onChange, value },
  } = useInput({ source });

  if (!permissions?.includes('order_edit')) return null;

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={advancedActions || false}
          onChange={(e) => {
            onChange(record?.paymentStatus);
            setAdvancedActions(e.target.checked);
          }}
        />
      }
      label="Advanced"
    />
  );
};

const Spacer = () => <Box mb={1}>&nbsp;</Box>;

const OrderForm = () => {
  const { permissions } = usePermissions();
  const { balance } = useNodalBalance();
  const mutationMode = 'pessimistic';
  const { record, save } = useEditController({
    mutationMode,
    redirect: false,
  });
  const [mode, setMode] = React.useState('IMPS');
  const [adjustments, setAdjustments] = React.useState(false);
  const [utr, setUtr] = React.useState('');
  const notify = useNotify();
  const refresh = useRefresh();
  const [saving, setSaving] = React.useState(false);
  const [notifyUser, setNotifyUser] = React.useState(false);
  const [advancedActions, setAdvancedActions] = React.useState(false);
  const [editPayee, setEditPayee] = React.useState<any>(false);

  const handleSubmit = async (data: any, event: any) => {
    if (!save) return;
    event?.preventDefault();

    if (
      Number(balance) < data.priceBreakUp.settlementAmount &&
      data.paymentStatus === paymentStatusMap.SETTLEMENT_INITIATED
    ) {
      alert(`Insufficient Nodal Balance`);
      return;
    }

    setSaving(true);

    await save(
      {
        paymentStatus: data.paymentStatus,
        ...((data.paymentStatus === paymentStatusMap.SETTLEMENT_INITIATED ||
          data.paymentStatus === paymentStatusMap.REFUND_INITIATED) && {
          settlementMode: mode,
        }),
        ...(data.paymentStatus === paymentStatusMap.SETTLEMENT_COMPLETED && {
          utrNo: utr,
        }),
        ...(data.paymentStatus === paymentStatusMap.RECEIVED && {
          notify: notifyUser,
        }),
      },
      {
        onError: (error) => {
          notify(`Error: ${error.message}`, { type: 'error' });
        },
        onSuccess: () => {
          setAdvancedActions(false);
          refresh();
          notify(
            `Order Updated as ${paymentStatusTitles[data.paymentStatus]}`,
            {
              messageArgs: { smart_count: 1 },
              undoable: false,
            }
          );
        },
      }
    );

    setSaving(false);
  };

  const editPayeeInfo = () => {
    if (editPayee) {
      changePayee(editPayee);
      setEditPayee(false);
    } else setEditPayee(true);
  };

  const changePayee = async (newPayeeId: string) => {
    const res = await fetch(
      `${apiUrl}/orders/${record?.clientReferenceId}/changePayee`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('auth') || '',
        },
        body: JSON.stringify({
          newPayeeId,
        }),
      }
    ).then((res) => res.json());
    if (res.code === 'MSG_0037') {
      notify('Payee Info updated successfully', {
        type: 'success',
      });
    } else {
      notify('Something went wrong', { type: 'error' });
    }

    refresh();
  };
  return (
    <Form onSubmit={handleSubmit}>
      <Stack
        sx={{
          flexDirection: {
            xs: 'column-reverse',
            sm: 'column-reverse',
            md: 'row',
          },
          gap: 2,
        }}
      >
        <Box>
          <PrevNextButtons sort={{ field: 'createdAt', order: 'DESC' }} />
          <Card sx={{ mb: 10 }}>
            <CardContent>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={8}>
                  <Typography variant="h6" gutterBottom>
                    Order
                  </Typography>
                  <Grid container>
                    <Grid item xs={12} sm={12} md={6}>
                      <Labeled
                        source="priceBreakUp.settlementAmount"
                        label="Settlement Amount"
                      >
                        <Stack direction="row" alignItems="center" spacing={1}>
                          <NumberField
                            source="priceBreakUp.settlementAmount"
                            options={{
                              style: 'currency',
                              currency: 'INR',
                            }}
                            sx={{
                              fontWeight: 'bold',
                              fontSize: '1.5rem',
                              ...(record?.adjustments?.settlementAmount && {
                                textDecoration: 'line-through',
                                fontSize: 15,
                                color: (t: any) => t.palette.text.disabled,
                              }),
                            }}
                          />
                          {record?.adjustments?.settlementAmount && (
                            <NumberField
                              source="adjustments.settlementAmount"
                              options={{
                                style: 'currency',
                                currency: 'INR',
                              }}
                              sx={{
                                fontWeight: 'bold',
                                fontSize: '1.5rem',
                              }}
                            />
                          )}
                        </Stack>
                      </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <Labeled
                        source="priceBreakUp.totalAmount"
                        label="Total Amount"
                      >
                        <NumberField
                          source="priceBreakUp.totalAmount"
                          options={{
                            style: 'currency',
                            currency: 'INR',
                          }}
                          sx={{ fontWeight: 'bold', fontSize: '1.5rem' }}
                        />
                      </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <Labeled source="createdAt" label="Created On">
                        <DateField
                          source="createdAt"
                          showTime
                          options={{
                            year: 'numeric',
                            month: 'short',
                            day: 'numeric',
                            hour12: true,
                            hour: 'numeric',
                            minute: 'numeric',
                          }}
                        />
                      </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <Labeled source="placedAt" label="Placed On">
                        <DateField
                          source="placedAt"
                          showTime
                          options={{
                            year: 'numeric',
                            month: 'short',
                            day: 'numeric',
                            hour12: true,
                            hour: 'numeric',
                            minute: 'numeric',
                          }}
                        />
                      </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <Labeled source="id" label="Reference Id">
                        <TextField source="id" />
                      </Labeled>
                    </Grid>

                    <Grid item xs={12} sm={12} md={6}>
                      <Labeled source="settlementBy" label="Settlement By">
                        <DateField
                          source="settlementBy"
                          showTime
                          options={{
                            year: 'numeric',
                            month: 'short',
                            day: 'numeric',
                            hour12: true,
                            hour: 'numeric',
                            minute: 'numeric',
                          }}
                        />
                      </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <Labeled source="clientInvoiceId" label="Invoice Id">
                        {record?.clientInvoiceId &&
                        record?.paymentStatus === 'SETTLEMENT_COMPLETED' ? (
                          <Link
                            target="_blank"
                            to={`https://paymadi.blr1.digitaloceanspaces.com/${record?.clientInvoiceId}.pdf`}
                          >
                            <TextField source="clientInvoiceId" />
                          </Link>
                        ) : (
                          <TextField emptyText="Not Available" />
                        )}
                      </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <Labeled source="settlementAt" label="Settlement At">
                        <DateField
                          emptyText="Not Available"
                          source="settlementAt"
                          showTime
                          options={{
                            year: 'numeric',
                            month: 'short',
                            day: 'numeric',
                            hour12: true,
                            hour: 'numeric',
                            minute: 'numeric',
                          }}
                        />
                      </Labeled>
                    </Grid>
                    {/* <Grid item xs={12} sm={12} md={6}>
                      <Labeled source="paymentMethod" label="Payment Method">
                        <TextField source="paymentMethod" />
                      </Labeled>
                    </Grid> */}
                    <Grid item xs={12} sm={12} md={6}>
                      <Labeled source="paymentType" label="Category">
                        <TextField source="paymentType" />
                      </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <Labeled source="settlementType" label="Settlement">
                        <TextField source="settlementType" />
                      </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <Labeled
                        source="pgDetail.selectedCardType"
                        label="Selected Card Type"
                      >
                        <TextField source="pgDetail.selectedCardType" />
                      </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <Labeled
                        source="pgDetail.selectedCardBank"
                        label="Selected Card Bank"
                      >
                        <TextField source="pgDetail.selectedCardBank" />
                      </Labeled>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Labeled source="serviceFee" label="Service Fee">
                        <TextField source="serviceFee" />
                      </Labeled>
                      {record?.paymentStatus === 'RECEIVED' && (
                        <FormControlLabel
                          sx={{ mr: 4, mt: 1 }}
                          control={
                            <Checkbox
                              disabled={!!record?.adjustments?.settlementAmount}
                              checked={
                                record?.adjustments?.settlementAmount ||
                                adjustments
                              }
                              onChange={(e) => {
                                setAdjustments(e.target.checked);
                              }}
                            />
                          }
                          label="Adjust"
                        />
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <Labeled source="utrNo" label="UTR No">
                        <TextField source="utrNo" emptyText="Not Available" />
                      </Labeled>
                    </Grid>
                    {(record?.adjustments?.settlementAmount || adjustments) && (
                      <Grid item xs={12} sm={12}>
                        <AdjustServiceFee
                          onClose={() => setAdjustments(false)}
                        />
                      </Grid>
                    )}
                  </Grid>
                  {record?.pgDetail?.merchantReferenceId &&
                    permissions?.includes('order_edit') && (
                      <>
                        <Divider sx={{ my: 1 }} />
                        <Grid container>
                          <Grid item xs={12} sm={12} md={6}>
                            <Labeled
                              source="pgDetail.pgName"
                              label="Payment Gateway (PG)"
                            >
                              <TextField source="pgDetail.pgName" />
                            </Labeled>
                          </Grid>
                          <Grid item xs={12} sm={12} md={6}>
                            <Labeled
                              source="pgDetail.merchantPaymentId"
                              label="PG Payment Id"
                            >
                              <TextField
                                source="pgDetail.merchantPaymentId"
                                emptyText="Not Available"
                              />
                            </Labeled>
                          </Grid>
                          <Grid item xs={12} sm={12} md={6}>
                            <Labeled
                              source="pgDetail.merchantReferenceId"
                              label="PG Order Id"
                            >
                              <TextField
                                source="pgDetail.merchantReferenceId"
                                emptyText="Not Available"
                              />
                            </Labeled>
                          </Grid>
                          <Grid item xs={12} sm={12} md={6}>
                            {record?.paymentStatus ===
                            paymentStatusMap.FAILED ? (
                              <Labeled
                                source="pgDetail.errorDesc"
                                label="PG Failure Reason"
                              >
                                <TextField
                                  color="error"
                                  source="pgDetail.errorDesc"
                                  emptyText="Not Available"
                                />
                              </Labeled>
                            ) : (
                              <Labeled
                                source="pgDetail.merchantSettlementId"
                                label="PG Settlement Id"
                              >
                                <TextField
                                  source="pgDetail.merchantSettlementId"
                                  emptyText="Not Available"
                                />
                              </Labeled>
                            )}
                          </Grid>
                          {record?.pgDetail?.pgName === 'RAZORPAY' && (
                            <RazorpaySettle />
                          )}
                        </Grid>
                      </>
                    )}

                  {record?.pgDetail?.pgName === 'MANUAL' && (
                    <>
                      <Divider sx={{ my: 1 }} />
                      <AddManualLink />
                    </>
                  )}
                  {record?.pgDetail?.pgName === 'MASTER PAY' && (
                    <>
                      <Divider sx={{ my: 1 }} />
                      <LinkProgress />
                    </>
                  )}
                  <Divider sx={{ my: 1 }} />
                  <Grid container>
                    <Grid item xs={12} sm={12} md={6}>
                      <Labeled source="paymentStatus" label="Payment Status">
                        <FunctionField
                          source="paymentStatus"
                          label="Status"
                          render={renderPaymentStatus}
                        />
                      </Labeled>
                    </Grid>
                    {record?.paymentStatus !==
                      paymentStatusMap.SETTLEMENT_COMPLETED &&
                      record?.paymentStatus !==
                        paymentStatusMap.REFUND_COMPLETED && (
                        <AdvancedCheckbox
                          advancedActions={advancedActions}
                          setAdvancedActions={setAdvancedActions}
                          source="paymentStatus"
                        />
                      )}
                  </Grid>
                  {advancedActions && (
                    <Grid container>
                      <Grid item xs={12} sm={12} md={6}>
                        <SelectInput
                          source="paymentStatus"
                          choices={[
                            {
                              id: paymentStatusMap.RECEIVED,
                              name: 'Received',
                            },
                            {
                              id: paymentStatusMap.ON_HOLD,
                              name: 'Put On Hold',
                              // disabled: true,
                            },
                            {
                              id: paymentStatusMap.CANCELLED,
                              name: 'Cancel Order',
                            },
                            ...((record?.paymentStatus === 'RECEIVED' && [
                              {
                                id: paymentStatusMap.REFUND_INITIATED,
                                name: 'Refund Now',
                              },
                              {
                                id: paymentStatusMap.SETTLEMENT_INITIATED,
                                name: 'Settle Now',
                              },
                              {
                                id: paymentStatusMap.SETTLEMENT_COMPLETED,
                                name: 'Settled',
                              },
                            ]) ||
                              []),
                          ]}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <Stack mt={0} spacing={1} direction="row">
                          <SettleMode
                            mode={mode}
                            setMode={setMode}
                            source="paymentStatus"
                          />
                          <UtrNo
                            utr={utr}
                            setUtr={setUtr}
                            source="paymentStatus"
                          />
                          <ReceivedNotify
                            notify={notifyUser}
                            setNotify={setNotifyUser}
                            source="paymentStatus"
                          />
                        </Stack>
                      </Grid>
                    </Grid>
                  )}
                  {record?.paymentStatus === paymentStatusMap.RECEIVED &&
                    !advancedActions &&
                    permissions?.includes('order_edit') && (
                      <Grid container>
                        <Grid item xs={12} sm={12} md={6}>
                          <Stack mt={2} spacing={1} direction="row">
                            <SettleInput source="paymentStatus" />
                          </Stack>
                        </Grid>

                        <Grid item xs={12} sm={12} md={6}>
                          <Stack mt={0} spacing={1} direction="row">
                            <SettleMode
                              mode={mode}
                              setMode={setMode}
                              source="paymentStatus"
                            />
                          </Stack>
                        </Grid>
                      </Grid>
                    )}
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <Typography variant="h6" gutterBottom>
                    Customer
                  </Typography>
                  <ReferenceField
                    source="userId"
                    reference="customers"
                    link={false}
                  >
                    <CustomerDetails />
                  </ReferenceField>
                  <Spacer />

                  <Stack
                    direction="row"
                    sx={{
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Typography variant="h6" gutterBottom>
                      Payee Info
                    </Typography>
                    {record?.paymentStatus === paymentStatusMap.RECEIVED &&
                      permissions?.includes('order_edit') && (
                        <Button variant="outlined" onClick={editPayeeInfo}>
                          {editPayee ? 'Save' : 'Edit'}
                        </Button>
                      )}
                  </Stack>
                  <Stack>
                    {editPayee && (
                      <ReferenceInput
                        label="Select Payee"
                        source="payee"
                        reference="payees"
                        filter={{ userId: record.userId }}
                        // disabled
                      >
                        <SelectInput
                          label={'Select Payee'}
                          optionValue="id"
                          onChange={(e) => {
                            setEditPayee(e.target.value);
                          }}
                          optionText={({ accountNo, payerName }) => {
                            return payerName + ' - ' + accountNo;
                          }}
                        />
                      </ReferenceInput>
                    )}
                  </Stack>
                  <WrapperField source="payeeInfo">
                    <PayeeInfo />
                  </WrapperField>

                  {record?.clientInvoiceId &&
                    record?.pgDetail?.pgName !== 'MANUAL' && (
                      <CardDetails orderId={record?.clientReferenceId} />
                    )}
                </Grid>
              </Grid>
              <Spacer />

              <Typography variant="h6" gutterBottom>
                Total
              </Typography>
              <Box>
                <Totals />
              </Box>
            </CardContent>
            <Box sx={{ opacity: saving ? 0.5 : 1 }}>
              <OrderEditToolbar />
            </Box>
          </Card>
        </Box>

        <OrderAside />
      </Stack>
    </Form>
  );
};

export default OrderEdit;

/*
minus 0.1416% from the total amount

totalAmount * 0.1416
totalAmount - (totalAmount * 0.1416)

*/

/*

Active Forever
Regular Pay (T+1)
Monday 12:00 AM -> Tuesday 6:PM
Monday 11:59 PM -> Tuesday 6:PM
Tuesday 12:00 AM -> Wednesday 6:PM


(12:00 AM -> 7:59 PM) Active Time
Fast Pay (Same Day)
Monday > 8:00 PM -> Monday 11:PM

(8:00 AM -> 11:59 PM) Active Time
Instant Pay (Within 3 hours)
Monday  8:01 AM -> Monday 11:01 PM
Monday  11:59 PM -> Tuesday 2:59 AM


INITIATED -> CHARGING -> RECEIVED -> HOLD | COMPLETED

REFUND_INITIATED ->  REFUND_SUCCEEDED | REFUND_FAILED


RECEIVED -> 
|| (SETTLEMENT_INITIATED -> SETTLEMENT_COMPLETED | SETTLEMENT_FAILED) 
|| ON_HOLD 
|| (REFUND_INITIATED -> REFUND_COMPLETED | REFUND_FAILED)


*/
