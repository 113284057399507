import { Create, SimpleForm } from 'react-admin';
import { useCreateController } from 'react-admin';
import { BankInputs } from './BankEdit';
import { uploadImage } from '../../imageUploadDelete';

const BankCreate = () => {
  const { save } = useCreateController();

  const handleSubmit = async (data: any, event: any) => {
    if (!save) return;
    event?.preventDefault();

    save({
      isActive: data.enabled || true,
      bankId: data.id,
      bankName: data.name,
      ...(data?.logo?.rawFile && { logo: await uploadImage(data.logo) }),
    });
  };

  return (
    <Create title="Add a Bank">
      <SimpleForm onSubmit={handleSubmit}>
        <BankInputs />
      </SimpleForm>
    </Create>
  );
};

export default BankCreate;
