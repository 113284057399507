import {
  BooleanField,
  Datagrid,
  List,
  ListActions,
  NumberInput,
  TextField,
} from 'react-admin';

const RangeTypeList = () => {
  const rangeTypeFilters = [<NumberInput label="Amount" source="amount" />];

  return (
    <>
      <List
        exporter={false}
        actions={<ListActions hasCreate />}
        filters={rangeTypeFilters}
      >
        <Datagrid bulkActionButtons={false} rowClick="edit">
          <TextField label="Name" source="desc" />

          <TextField label="Min. Amount" source="minAmount" />
          <TextField label="Max. Amount" source="maxAmount" />
          <BooleanField label="Enabled" source="enabled" />
        </Datagrid>
      </List>
    </>
  );
};

export default RangeTypeList;
