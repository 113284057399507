import { Card, CardContent } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import { FilterList, FilterListItem, useGetList } from 'react-admin';

const statusTypeChoices = [
  { id: 'enabled', name: 'Enabled', value: true },
  { id: 'disabled', name: 'Disabled', value: false },
];

const CardBankTypesAside = () => {
  const { data: cardTypes = [] } = useGetList('cardTypes', {
    pagination: { page: 1, perPage: 100 },
  });
  return (
    <Card
      sx={{
        order: -1,
        flex: '0 0 15em',
        mr: 2,
        mt: 6,
        alignSelf: 'flex-start',
        height: '85vh',
        overflowY: 'auto',
      }}
    >
      <CardContent sx={{ pt: 1 }}>
        <FilterList label="Card Type" icon={<CheckCircleOutlineIcon />}>
          {cardTypes.map((cardType) => (
            <FilterListItem
              label={cardType.desc}
              value={{ cardTypeId: cardType.id }}
            />
          ))}
        </FilterList>

        <FilterList label="Status Type" icon={<CurrencyRupeeIcon />}>
          {statusTypeChoices.map(({ id, name, value }) => (
            <FilterListItem label={name} value={{ enabled: value }} />
          ))}
        </FilterList>
      </CardContent>
    </Card>
  );
};

export default CardBankTypesAside;
