import * as React from 'react';
import { Layout, LayoutProps } from 'react-admin';
import AppBar from './AppBar';
import Menu from './Menu';
import { Notes } from '../modules/notes/notes';

export default (props: LayoutProps) => (
  <Layout {...props} appBar={AppBar} menu={Menu}>
    <>
      {props.children}
      {/* <Notes /> */}
    </>
  </Layout>
);
