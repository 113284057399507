import { useWatch } from 'react-hook-form';
import { apiUrl } from './constants';
import { ImageInput } from 'react-admin';

export const uploadImage = async (data: any) => {
  const formData = new FormData();
  formData.append('imageUrl', data.rawFile);
  const response = await fetch(apiUrl + '/upload/images', {
    method: 'POST',
    body: formData,
    headers: {
      Authorization: localStorage.getItem('auth') || '',
    },
  });
  const res = await response.json();
  if (res.code === 'MSG_0031') {
    return res.data;
  }
  return null;
};

export const deleteImage = async (imageUrl: string) => {
  const response = await fetch(apiUrl + '/images?imageUrl=' + imageUrl, {
    method: 'DELETE',
    headers: {
      Authorization: localStorage.getItem('auth') || '',
    },
  });
  const res = await response.json();
  if (res.code === 'MSG_0030') {
    return true;
  }
  return null;
};

export const LogoUpload = () => {
  const imageUrl = useWatch<{ [keyName: string]: string }>({
    name: 'logo',
  }) as any;

  const styles = {
    imageWrapper: {
      img: {
        borderRadius: 1,
        width: '100%',
        objectFit: 'cover',
      },
    },
  };

  return (
    <ImageInput source="logo" label={'Logo'} sx={styles.imageWrapper}>
      {(imageUrl?.src || imageUrl) && (
        <img src={imageUrl?.src || imageUrl} height={200} />
      )}
    </ImageInput>
  );
};
