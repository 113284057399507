import { useState, useCallback } from 'react';
import { apiUrl, cardDetailsKey as decryptionKey } from '../constants';
import { useNotify } from 'react-admin';

const useDecryptData = () => {
  const notify = useNotify();
  const [data, setData] = useState<any>(null);
  const [dataLoading, setDataLoading] = useState(false);

  const decryptData = useCallback(async (input: string) => {
    const formData = new FormData();
    formData.append('input', input);
    try {
      setDataLoading(true);
      const res = await fetch(`${apiUrl}/util/decrypt/response`, {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: localStorage.getItem('auth') || '',
          privateKey: decryptionKey || '', // Provide a default value for privateKey
        } as Record<string, string>, // Explicitly provide the type for headers
      }).then((res) => res.json());

      if (res.code === 'MSG_0081') {
        setData(res.data);
      } else {
        notify(res.data, { type: 'error' });
      }
      setDataLoading(false);
    } catch (e) {
      console.error(e);
      setDataLoading(false);
    }
  }, []);

  const reset = useCallback(() => {
    setData(null);
  }, []);

  return { data, dataLoading, decryptData, reset };
};

export default useDecryptData;
