import {
  Collapse,
  IconButton,
  IconButtonProps,
  Stack,
  Typography,
  alpha,
  styled,
} from '@mui/material';
import useDecryptData from '../hooks/useDecryptData';
import { useEffect, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const CardImage = ({ input, title }: { input: string; title: string }) => {
  const [expanded, setExpanded] = useState(false);

  const { data, dataLoading, decryptData, reset } = useDecryptData();

  const handleExpandClick = () => {
    if (!expanded && !dataLoading && !data) {
      decryptData(input);
    }
    setExpanded(!expanded);
  };

  useEffect(() => {
    setExpanded(false);
    reset();
  }, [input]);

  console.log('data', data);

  return (
    <Stack my={1}>
      <Stack
        direction="row"
        alignItems="center"
        onClick={handleExpandClick}
        sx={{
          borderRadius: 1,
          border: 1,
          borderColor: (t) => alpha(t.palette.text.primary, 0.12),
          paddingX: 1,
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: (t) => alpha(t.palette.text.primary, 0.04),
          },
        }}
      >
        <Typography variant="body1" fontWeight="bold">
          {title}
        </Typography>
        <ExpandMore
          expand={expanded}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </Stack>

      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Stack mt={2}>
          {dataLoading && <Typography variant="body2">Loading...</Typography>}
          {data && (
            <img
              src={data}
              alt={title}
              style={{ maxWidth: '100%', maxHeight: '100%' }}
            />
          )}
        </Stack>
      </Collapse>
    </Stack>
  );
};

export default CardImage;
