import {
  BooleanInput,
  Create,
  FormDataConsumer,
  SimpleForm,
  TextField,
  TextInput,
  useCreateController,
} from 'react-admin';
import { rangeTypeValidations } from './RangeTypeEdit';
import { useEffect } from 'react';
import { useForm, useFormContext } from 'react-hook-form';
import { Box } from '@mui/material';

export const RangeTypeInputs = () => {
  return (
    <FormDataConsumer>
      {({ formData, ...rest }) => (
        <>
          <TextInput
            source="minAmount"
            type="number"
            validate={rangeTypeValidations.minAmount(formData)}
          />
          <TextInput
            source="maxAmount"
            type="number"
            validate={rangeTypeValidations.maxAmount(formData)}
          />
          <BooleanInput label="Enabled" source="enabled" />
          <RenderName formData={formData} />
        </>
      )}
    </FormDataConsumer>
  );
};

const RangeTypeCreate = () => {
  const { save } = useCreateController();

  const handleSubmit = async (data: any, event: any) => {
    if (!save) return;
    event?.preventDefault();

    save({
      minAmount: data.minAmount,
      maxAmount: data.maxAmount,
      rangeTypeDesc: data.name,
      isActive: data.enabled,
    });
  };

  return (
    <Create title="Create a Post">
      <SimpleForm onSubmit={handleSubmit}>
        <RangeTypeInputs />
      </SimpleForm>
    </Create>
  );
};

const RenderName = ({ formData }: any) => {
  const { minAmount, maxAmount, name } = formData;
  const { setValue } = useFormContext();
  useEffect(() => {
    const name = getRangeTypeName({ maxAmount, minAmount });

    setValue('name', name);
  }, [maxAmount, minAmount]);

  return <Box>Type Name: {name}</Box>;
};

const getRangeTypeName = ({ minAmount, maxAmount }: any): string => {
  return `${makeFriendly(minAmount)} to ${makeFriendly(maxAmount)}`;
};
function intlFormat(num: number) {
  return new Intl.NumberFormat().format(Math.round(num * 10) / 10);
}
function makeFriendly(num: number) {
  if (isNaN(num)) return '--';
  if (num >= 100000) return intlFormat(num / 100000) + 'L';
  if (num >= 1000) return intlFormat(num / 1000) + 'k';
  return intlFormat(num);
}

export default RangeTypeCreate;
