import {
  AutocompleteInput,
  DateInput,
  ReferenceInput,
  SearchInput,
  SelectInput,
  useGetList,
} from 'react-admin';
import { Customer } from '../types';

export const useGetFilters = () => {
  const { data: _settlementTypes = [] } = useGetList('settlementTypes', {
    pagination: { page: 1, perPage: 100 },
  });
  const settlementTypes = Array.from(
    new Set(_settlementTypes.map((st) => st.name))
  ).map((name) => _settlementTypes.find((st) => st.name === name));

  const { data: paymentTypes = [] } = useGetList('paymentTypes', {
    pagination: { page: 1, perPage: 100 },
  });
  const statusTypes = [
    { name: 'Active', id: 'active', status: true },
    { name: 'In-active', id: 'inactive', status: false },
  ];

  // const merchantCodes = _merchantCodes?.reduce((acc: any, item: any) => {
  //   if (!acc[item.merchantId]) {
  //     acc[item.merchantId] = item;
  //   }
  //   return acc;
  // });

  return {
    settlementTypes,
    paymentTypes,
    statusTypes,
  };
};

export const userConfigFilters = ({
  settlementTypes,
  statusTypes,
  paymentTypes,
}: any) => [
  <ReferenceInput label="Customers" source="userId" reference="customers">
    <AutocompleteInput
      optionText={(choice?: Customer) =>
        choice?.id // the empty choice is { id: '' }
          ? `${choice?.personalDetails?.firstName} ${choice?.personalDetails?.lastName}`
          : ''
      }
      sx={{ minWidth: 200 }}
    />
  </ReferenceInput>,
  <DateInput label="Created After" source="createdAtGTE" />,
  <DateInput label="Created Before" source="createdAtLTE" />,
  <SelectInput
    label="Payment Type"
    source="paymentTypeId"
    choices={paymentTypes}
    optionText={(choice?: any) => (choice?.id ? `${choice?.desc}` : '')}
  />,
  <SelectInput
    label="Settlement Type"
    source="settlementTypeId"
    choices={settlementTypes.map((i: any) => ({
      id: i.settlementTypeId,
      name: i.description,
    }))}
    optionText={(choice?: any) => (choice?.id ? `${choice?.name}` : '')}
  />,
  <SelectInput
    label="Status"
    source="active"
    choices={statusTypes}
    optionValue="status"
    optionText={(choice?: any) => `${choice?.name}`}
  />,
];
