import { Card, CardContent } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import { FilterList, FilterListItem, useGetList } from 'react-admin';
import { useGetFilters } from './SettlementTypeFilters';

const SettlementTypeAside = () => {
  const { paymentTypes, settlementTypes, statusTypeChoices } = useGetFilters();

  return (
    <Card
      sx={{
        order: -1,
        flex: '0 0 15em',
        mr: 2,
        mt: 6,
        alignSelf: 'flex-start',
        height: '85vh',
        overflowY: 'auto',
      }}
    >
      <CardContent sx={{ pt: 1 }}>
        <FilterList label="Payment Type" icon={<CheckCircleOutlineIcon />}>
          {paymentTypes.map((paymentType) => (
            <FilterListItem
              label={paymentType.desc}
              value={{ paymentTypeId: paymentType.id }}
            />
          ))}
        </FilterList>

        <FilterList label="Settlement Type" icon={<AccessTimeIcon />}>
          {settlementTypes.map((settlementType) => (
            <FilterListItem
              label={settlementType.description}
              value={{ settlementTypeId: settlementType.settlementTypeId }}
            />
          ))}
        </FilterList>

        <FilterList label="Status Type" icon={<CurrencyRupeeIcon />}>
          {statusTypeChoices.map(({ id, name, value }) => (
            <FilterListItem label={name} value={{ enabled: value }} />
          ))}
        </FilterList>
      </CardContent>
    </Card>
  );
};

export default SettlementTypeAside;
