import { LoadingIndicator, LocalesMenuButton } from 'react-admin';

import { ThemeSwapper } from '../themes/ThemeSwapper';
import { Notes } from '../modules/notes/notes';
import { useLocation } from 'react-router';

export const AppBarToolbar = () => {
  const location = useLocation();

  console.log(location);
  return (
    <>
      {/* <LocalesMenuButton /> */}
      <ThemeSwapper />
      <LoadingIndicator />
      <Notes />
    </>
  );
};
