import { useState } from 'react';
import { Typography, IconButton, Tooltip, Alert } from '@mui/material';
import { useNotify, useRecordContext, useRefresh } from 'react-admin';
import { apiUrl } from '../constants';
import Refresh from '@mui/icons-material/Cached';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';

const generate = async ({ customerId }: { customerId: string }) => {
  const formdata = new FormData();
  formdata.append('regenerateKyc', 'true');
  //   formdata.append('name', 'test');
  const response = await fetch(`${apiUrl}/customers/${customerId}`, {
    method: 'PUT',
    body: formdata,
    headers: {
      Authorization: localStorage.getItem('auth') || '',
    },
  }).then((res) => res.json());

  return response;
};

const RegenerateKYC = ({ customerId }: { customerId: string }) => {
  const [open, setOpen] = useState(false);
  const record = useRecordContext();
  const refresh = useRefresh();
  const notify = useNotify();

  const handleCancel = () => {
    setOpen(false);
  };

  const handleOk = () => {
    generate({ customerId }).then(() => {
      setOpen(false);
      refresh();
      notify('KYC Regenerated');
    });
  };

  if (
    record?.kycStatus === 'auto_approved' ||
    record?.kycStatus === 'manually_approved' ||
    record?.kycStatus === 'manually_declined'
  ) {
    return null;
  }

  return (
    <>
      <Dialog maxWidth="xs" open={open}>
        <DialogTitle>Regenerate KYC?</DialogTitle>
        <DialogContent dividers>
          <Typography variant="body1">
            Regenerating KYC will delete the existing KYC data.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button endIcon={<Refresh />} variant="contained" onClick={handleOk}>
            Regenerate KYC
          </Button>
        </DialogActions>
      </Dialog>
      <Tooltip title="Regenerate KYC">
        <IconButton onClick={() => setOpen(true)}>
          <Refresh fontSize="small" />
        </IconButton>
      </Tooltip>
    </>
  );
};

export default RegenerateKYC;
