import RangeTypeCreate from './RangeTypeCreate';
import RangeTypeEdit from './RangeTypeEdit';
import RangeTypeList from './RangeTypeList';

const resource = {
  list: RangeTypeList,
  edit: RangeTypeEdit,
  create: RangeTypeCreate,
};

export default resource;
